import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'


import { connect } from 'react-redux'
import styled from 'styled-components';

import AlbumTab from './album-tab';
import About from './about-tab';
import ProfileHead from './profile-head';
import PhotoTab from './photo-tab';
import Likes from './likes-tab';

import TabAnchorBar from './tab-anchor-bar';
import Loader from '../widget/loader/loader';
import Void from '../widget/void-content/void';

import { _getUserByUsername } from '../../container/redux/services/user.service';
import InfiniteLoader from '../widget/loader/infiniteLoader';


const StyledProfile = styled.div`
    margin-bottom: 60px;
    background: #fcfcfc;
    & .head{
        position: relative;
        padding-bottom: 5px;
        border-bottom: 1px solid #eee
    }
    & .profile-content .photos{
        columns: 3em ;
        column-gap: 10px;
        column-count: 4;
    }
    & .profile-content .photos .photo{
        word-break: keep-all;
    }

    @media(max-width: 650px){
        & .profile-content{
            padding: 20px 0px !important
        }
    }
`




const Profile = (props)=>{

    const { 
        location,
        match: {
            params: {
                id : guestUserName
            } = {}
        } = {},
        dispatch,
        state
    } = props
    const { authenticatedUser, guestUser, loading, error } = state || {}
    const { username: authuserName } = authenticatedUser || {}
    const [ isUser, setIsUser ] = useState({
        isAuth: false,
        currentUser: null
    })
   
    useEffect(()=>{
        if( guestUserName !== authuserName ){
            dispatch(_getUserByUsername(guestUserName))
        }else{
            setIsUser({
                isAuth: true,
                currentUser: authenticatedUser
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ guestUserName ])
    
    useLayoutEffect(()=>{
        if(  guestUserName === authuserName ){
            setIsUser({
                isAuth: true,
                currentUser: authenticatedUser
            })
        }else{
            setIsUser({
                isAuth: false,
                currentUser: guestUser
            })
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ state ])

 

    const pathDetails = location.pathname.split('/')
    const route = pathDetails[ pathDetails.length - 1 ]


    const loadTabFromHash = (route)=>{
        
        switch(route){
            case "photos" :
                return 0
            case "collection" :
                return 1
            case "likes" :
                return 2
            case "about" :
                return 3
            default:
                return null
        }
    }

    // SET TAB CURRENT TAB
    const [ tab, setTab ] = useState(null)
    let offset 
    let tabPositionSpan; 
    const [ tabPosition, setTabPosition ] = useState({
        loci: offset,
        tabPositionSpan
    })
    useEffect(()=>{
        if( route && pathDetails.length > 2 ){
            setTab( loadTabFromHash(route) )
        }else{
            setTab( 0 )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ pathDetails ])


    // Load tab
    const handleEvent = (e)=>{
        const { tabIndex, offsetLeft, clientWidth } = e.target

        setTab(tabIndex)
        setTabPosition({
            loci: offsetLeft,
            span: clientWidth
        })
    }

    // SET A DEFAULT POSITION ONLOAD
    useEffect(()=>{
        !route && setTabPosition({
            loci: 0,
        })
        !route && setTab(0)
    }, [ route ])
    
    



 
    return(
        <>
            {
                isUser.currentUser ?
                <StyledProfile className='profile-wrapper'>
                    {
                        loading && <InfiniteLoader />
                    }
                    <div className="white head">
                        <div className='in-container'>
                            <ProfileHead 
                                dispatch={dispatch} 
                                user={{...isUser, authenticatedUser, loading}} 
                            />
                        </div>
                    </div>
                    <TabAnchorBar 
                        user={ isUser.currentUser }
                        tab={tab} 
                        tabPosition={tabPosition} 
                        handleEvent={handleEvent}
                        setTabPosition={setTabPosition}
                    />
                    <div className='white padded-20 profile-content'>
                        <div className='bezless container'>
                            <Switch>
                                <Route 
                                    exact
                                    path={`${props.match.path}`}  
                                    render={ ()=>{ return <PhotoTab { ...isUser } /> }} />
                                <Route 
                                    exact
                                    path={`${props.match.path}/collection`}  
                                    render={ ()=>{ return <AlbumTab { ...isUser } /> }} />
                                <Route 
                                    exact
                                    path={`${props.match.path}/likes`}  
                                    render={ ()=>{ return <Likes { ...isUser } /> }} />
                                <Route 
                                    exact
                                    path={`${props.match.path}/about`}  
                                    render={ ()=>{ return <About { ...isUser } /> }} />
                                
                                <Redirect from="*" to="/" component={ Profile } />
                            </Switch>
                        </div>
                    </div>
                </StyledProfile>
                : 
                !loading && error &&
                <Void 
                    adjustposition
                    title={error}
                    description='We could not complete your request'
                    link={
                        {
                            route: '/',
                            text: 'Return to Home',
                        }
                    }

                />
            }
            {
                loading  && 
                <>
                    <InfiniteLoader />
                    <Loader message="Loading profile..."/> 
                </>
            }
        </>
    )
}







const mapStateToProps = state =>{
    return{
        state: state.user
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        dispatch: (action)=>{
            dispatch(action)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)