const getCollectionInfo = (id, collections)=>{
    let collection = null
    if( id && collections ){
        for( let c in collections ){
            let index = collections[c]
            if( index._id === id ){
                collection = index
            }
        }
        return collection ? collection : null
    }
}


export default getCollectionInfo