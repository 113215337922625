import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import styled from 'styled-components';
import Photo from '../widget/photo/photo'
import EmptyContent from '../widget/empty-content/empty-content';
import { StyledResponsivePhotoTab } from './photo-tab';
import TabIntro from './tab-intro';
import { _fetchLikedPhotos } from '../../container/redux/services/photo.service';


const StyledLikes = styled.div`
    & .title{
        padding: 10px 0px 40px 0px;
    }

`

const Likes = (props) =>{

    const { 
        dispatch, 
        state: { 
            likedPhotos
        } = {}, 
        isAuth, 
        currentUser: { 
            name, 
            username 
        } = {} 
    } = props

    const { docs, totalDocs } = likedPhotos || {}
    useEffect(() => {
        if( !docs ){
            _fetchLikedPhotos(dispatch, username)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ username ])

    
    

    return(
        <>
            <TabIntro
                title={`Photos ${ isAuth ? 'you' : name } liked (${ totalDocs })`}
                description={`${ isAuth ? 'You can remove a photo from this list by unliking it' : ''}`}
                />
            <StyledLikes>
                {
                    totalDocs ? 
                        <StyledResponsivePhotoTab>
                            <div className='liked-photos'>
                                {
                                    docs.map( ( photo, index ) =>{
                                        return <Photo 
                                        payload={ photo.post } 
                                        key={ index } 
                                        />
                                    } )
                                }
                            </div>
                        </StyledResponsivePhotoTab>
                    : 
                        <EmptyContent 
                            title={`
                                ${ isAuth ? 'You have not liked any photos, click on the heart icon on any photo you like to add it here' : 
                                name + ' has not liked any photos'}
                            `}>
                            <svg 
                                className='resize'
                                xmlns="http://www.w3.org/2000/svg" 
                                width="24" 
                                height="24" 
                                viewBox="0 0 24 24">
                                <path d="M6.084 4c1.379 1.612 2.576 3 4.916 3h11v13h-20v-16h4.084zm.916-2h-7v20h24v-17h-13c-1.629 0-2.305-1.058-4-3zm3.715 9.652l-2.921.404 2.125 2.042-.518 2.902 2.599-1.39 2.599 1.39-.518-2.902 2.125-2.042-2.921-.404-1.285-2.652-1.285 2.652z"/>
                            </svg>
                        </EmptyContent>
                }
            </StyledLikes>
        </>
    )
}








const mapStateToProps = state =>{
    return{
        state: state.like
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        dispatch: action =>{dispatch(action)}
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(Likes)