import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom'
import generateCollectionCoverImage from '../../../utilities/generateCollectionCoverImage';
import shortenText from '../../../utilities/shortenText';

import { ReactComponent as ImageSvg } from '../../../assets/picture.svg'
import randID from '../../../utilities/randID';
import getAttribute from '../../../utilities/getAttribute';
import { _deleteCollection } from '../../../container/redux/services/collection.service';


const StyledAlbum = styled.div`
    position: relative; 
    min-width: 250px;
    margin: 5px;
    overflow: hidden;
    
    & .context-menu{
        right: 10px;
        top: 60px;
        height: 30px;
        width: 30px;
        background: rgba(0,0,0,.5);
        border-radius: 100%;
        box-sizing: border-box;
        text-align: center;
        padding: 5px 0px 5px 2px;
        color: #fff;
        cursor: pointer;
        z-index: 1
    };
    & .context-menu:hover{
        background: rgba(0,0,0,.8);
    }
    & .context-menu i {
        font-size: 20px;
        color: #ddd;
    }

    & .album-head{
        align-items: center
    }
    
    & .album-head .album-title{
        margin-bottom: 8px;
        text-transform: capitalize;
        font-size: clamp( 14px, 50vw, 16px)
    }
    & .album-head .count{
        font-size: 12px;
    }
    & .album-head .count svg{
        fill: dimgrey
    }
    & .album-grid{
        overflow: hidden;
        height: 200px;
        border-radius: 5px;
        position: relative; 
        transition: .3s;
        transform: translateX(0px);
    }
    & .album-grid.del-context{
        transform: translateX(-20px);
        transition: .3s;
    }
    & .tall-img{
        box-sizing: border-box
    }
    & .img{
        margin: 0px !important;
        height: 100%;
        width: 100%;
        overflow: hidden;
        background: #f2f2f2;
    }
    & .img img{
        margin: 0px;
        min-width: 100%;
        height: 100%;
        object-fit: cover;
    }
    & .img.rec-img{
        border-left: 1.2px solid #ddd;
        display: flex;
        flex-direction: column;
    }
    & .img.rec-img img:nth-child(2){
        border-top: 1.2px solid #ddd;
    }
    & .album-foot.tags{
        margin-top: 5px;
    }
    & .album-foot.tags .tag{
        font-size: 12px;
        background: #fafafa;
        border-radius: 3px;
        padding: 5px 10px;        
    }
    
    @media(max-width: 650px){
        width: 100%; 
        margin: 15px auto !important;
        max-width: 100%;
        & .album-grid{
            height: 230px;
            border-radius: 0px;
        }
        & .album-head,
        .album-foot{
            padding: 0px 8px;
        }
    }

`
const StyledAlbumContextMenu = styled.ul`
    width: fit-content;
    padding: 0px 20px;
    display: flex;
    margin: 0px;
    align-items:center;
    background: rgba(229,28,35,0.09);
    border-radius: 50px;
    & i{
        transform: scale(1)
    }
    & i {
        font-size: 40px;
        cursor: pointer;
        color: #d60c0c;
        animation: bulge .2s ease-out;
    }
    @keyframes bulge{
        from{
            transform: scale(.5)
        }
        to{
            transform: scale(1)
        }
    }
`

const AlbumContextMenu = ({handleContext})=>{

    return(
        <StyledAlbumContextMenu className='context'>
            <i
             data-action='delete-collection'
             onClick={handleContext} 
            className='material-icons'>delete</i>
        </StyledAlbumContextMenu>
    )
}

const AlbumList = (props)=>{

    const { 
        album: { 
            _id,
            name: title, 
            photos,
        } = {}, 
        isAuth,
        dispatch
    } = props

    // Collection cover image manipulation
    const { 
        length, 
        tall, 
        extraOne, 
        extraTwo , 
        extrasHeight 
    } = generateCollectionCoverImage(photos) || {}

    const albumCoverBackup = {
        background: length ? null : '#f2f2f2'
    }

    // Collection link and title
    const collectionLink = `/collections/${_id}/${title.split(' ').join('_').toLocaleLowerCase()}`
    const useTitle = shortenText(title,3,'...')
    const tags = ['People', 'Women','children']

    const [ context, setContext ] = useState(false)
    const handleContext = (e)=>{
        const action = getAttribute(e.target, 'action')
        if( action === 'delete-collection'){
            // Delete collection
            _deleteCollection(_id, dispatch)
        }else{
            setContext(!context)
        }
    }
    
    



    return(
            <StyledAlbum className='relative tab-content album'>
             {
                 isAuth && 
                 <>
                    <span onClick={handleContext} className='absolute context-menu'>
                        <i className='fa fa-ellipsis-h'></i>
                    </span>
                 </>
             }
                <div className='album-prop'>
                    <div className='grid apart album-head'>
                        <h3 className='album-title'>{useTitle}</h3>
                        <small className='count grid align-c '>
                            <p>{length}</p> 
                            <ImageSvg />
                        </small>
                    </div>
                    <div className='no-wrap grid album-main'>
                        <NavLink className=' no-margin full-width' to={collectionLink}>
                            <div style={albumCoverBackup} className={`no-wrap equal grid album-grid ${ context ? 'del-context' : ''}`}>
                                {
                                    length ?
                                        <div className='img tall-img'>
                                            <img src={tall} alt={'cover'} />
                                        </div>
                                    : null
                                }
                                {/* Show more photos if there are more */}
                                {
                                    length >= 2 ? 
                                    <div className='img rec-img'>
                                        <img src={extraOne} alt={'cover'} style={extrasHeight} />
                                        {
                                            length >= 3 && 
                                            <img src={extraTwo} alt={'cover'} style={extrasHeight} />
                                        }
                                    </div>
                                    : null
                                }
                            </div>
                        </NavLink>
                        {
                            context && <AlbumContextMenu handleContext={handleContext}/>
                        }
                    </div>
                    <div className='grid album-foot tags'>
                        {
                            tags && 
                            tags.map( tag => {
                                return(
                                    <NavLink
                                        className='tag' 
                                        to={`images/${tag}`}
                                        key={randID()}
                                        >{tag}
                                    </NavLink>
                                )
                            })
                        }
                    </div>
                </div>
            </StyledAlbum>
    )
}



export default AlbumList