import * as actions from './upload-actions'


export const uploadRequest = ()=>{
    return{
        type: actions.UPLOAD_PHOTO_REQUEST,
    }
}


export const uploadSuccess = (payload)=>{
    return{
        type: actions.UPLOAD_PHOTO_SUCCESS,
        payload
    }
}


export const uploadFailure = (payload)=>{
    return{
        type: actions.UPLOAD_PHOTO_FAILURE,
        payload
    }
}

export const resetUploadState = ()=>{
    return{
        type: actions.RESET_UPLOAD_STATE
    }
}
