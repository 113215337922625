import React from 'react';
import "./App.css"
import { Provider } from "react-redux"

import store from './container/redux/store/store';
import Alerts from './component/widget/alerts/alert';
import Appwrapper from './component/app-wrapper/appwrapper';




const App = ()=>{ 
    

    return(
        <Provider store={store}>
            <React.Fragment>
                <Appwrapper />
                <Alerts />
            </React.Fragment>
        </Provider>
    )
}

export default App
