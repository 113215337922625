import axios from 'axios';
import * as collectionActions from '../actions/collections-action/collection-action-creator'
import authHeader from '../../../utilities/authHeader';
import AxiosCall from '.';



// Create collection
export const _createCollection = (payload)=>{
    return (dispatch)=>{
        dispatch(collectionActions.createCollection())

        const API_ENDPOINT = AxiosCall( 'post/album/create' )
        const MY_HEADERS = {
            Authorization: `Bearer ${authHeader()}`
        }
    
        const REQUEST_OPTIONS = {
            method: 'POST',
            headers: MY_HEADERS,
            data: payload,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data } = {} } = response
            // UPDATE FOLLOW LIST
            dispatch( collectionActions.createCollectionSuccess(data))
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch( collectionActions.createCollectionFailure({ msg }))
        })
    }
}

// Fetch collections
export const _fetchCollection = (payload, dispatch)=>{
    function loadFetch(dispatch){
        dispatch(collectionActions.fetchCollection(payload))
        
        const API_ENDPOINT = AxiosCall(`user/albums?username=${payload}` )
        const REQUEST_OPTIONS = {
            method: 'GET',
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data } = {} } = response
            dispatch( collectionActions.fetchCollectionSuccess(data))
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch( collectionActions.fetchCollectionFailure({msg}))
        })
    }
    return loadFetch(dispatch)
}

// Delete collection
export const _deleteCollection = (collectionId, dispatch)=>{
    function removeCollection(dispatch){
        const API_ENDPOINT = AxiosCall(`/post/album/delete/${collectionId}` )
        const MY_HEADERS = {
            Authorization: `Bearer ${authHeader()}`
        }
    
        const REQUEST_OPTIONS = {
            method: 'DELETE',
            headers: MY_HEADERS,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data: { msg } } = {} } = response
            dispatch( collectionActions.deleteCollection({msg, target: collectionId}))
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch( collectionActions.deleteCollection({msg}))
        })
    }
    return removeCollection(dispatch)
}

// Get collection
export const _getCollection = (collectionId, dispatch)=>{
    function getCollection(dispatch){
        dispatch(collectionActions.getCollectionRequest())

        const API_ENDPOINT = AxiosCall(`post/album/get/${collectionId}`) 
        const MY_HEADERS = {
            Authorization: `Bearer ${authHeader()}`
        }
    
        const REQUEST_OPTIONS = {
            method: 'GET',
            headers: MY_HEADERS,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data } = {} } = response
            dispatch( collectionActions.getCollectionSuccess(data.album))
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch( collectionActions.getCollectionFailure({msg}) )
        })
    }
    return getCollection(dispatch)
}


// Add photo to collection
export const _addPhotoToCollection = (payload)=>{
    const { postId, albumId } = payload
    return (dispatch)=>{
        dispatch(collectionActions.addToCollection())

        const API_ENDPOINT =  AxiosCall(`post/photo/add-to-album/${postId}/${albumId}`)
        const MY_HEADERS = {
            Authorization: `Bearer ${authHeader()}`
        }
    
        const REQUEST_OPTIONS = {
            method: 'POST',
            headers: MY_HEADERS,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data } = {} } = response
            dispatch( collectionActions.addToCollectionSuccess(data))
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch( collectionActions.addToCollectionFailure({msg}))
        })
    }
}