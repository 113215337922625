function randomBackground(array){
    if( array ){
        const randNum = Math.floor(Math.random() * array.length)
        const { author: { name, username } ={}, photo: { url } = {} } = array[randNum] || {}
        return {
            image:  url,
            author: `Photographer - ${ name }`,
            username
        }
    }
    return null
}

export default randomBackground