import React from 'react'
import Photo from '../../widget/photo/photo';
import randID from '../../../utilities/randID';
import EmptyContent from '../empty-content/empty-content';
import { ReactComponent as LinkSvg } from '../../../assets/link.svg'
import { StyledResponsivePhotoTab } from '../../profile/photo-tab';


const Related = ({ data, inViewMode })=>{
    const validateIfData = data ? true : false 

    return(
        <React.Fragment>
            {
                validateIfData ?
                <StyledResponsivePhotoTab 
                    related={true}
                    padding='0px 10px'
                >
                    {
                        validateIfData &&
                        data.map( (photo, index) => {
                            const { id, photographer, src: { portrait, landscape } = {} } = photo 
                            return(
                                index % 2 === 0 ? 
                                <Photo 
                                    payload={{url: portrait, id, author: photographer}} 
                                    index={index} 
                                    key={randID()}
                                    related={true}
                                    inViewMode
                                />
                                : 
                                <Photo 
                                    payload={{url: landscape, id, author: photographer}} 
                                    index={index} 
                                    key={randID()}
                                    related={true}
                                    inViewMode
                                />
                            ) 
                        })
                    }
                </StyledResponsivePhotoTab>
                : 
                <EmptyContent title={"No related photos found"}>
                    <LinkSvg className='resize'/>
                </EmptyContent>
            }
        </React.Fragment>
    )
}

export default Related