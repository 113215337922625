import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import styled from 'styled-components';
import randID from '../../../utilities/randID';
import shortenText from '../../../utilities/shortenText';
import getAttribute from '../../../utilities/getAttribute';
import isIncludedInArray from '../../../utilities/isIncludedInArray';
import PrimaryButton from '../../widget/buttons/buttons'
import { _createCollection, _fetchCollection, _addPhotoToCollection } from '../../../container/redux/services/collection.service';
import InfiniteLoader from '../loader/infiniteLoader';


const StyledForm = styled.div`
    & button.btn{
        margin: 10px 0px;
        padding: 10px 30px;
        background: var(--darker-blue);
        box-shadow: var(--soft-shadow);
        color: #fff
    }
`
const CollectionList = styled.div`
    margin-bottom: 35px;
    & li.added{
        border: 1px solid var(--light-green)
    }
    & .categories{
        display: flex !important;
        align-items: stretch;
        flex-wrap: nowrap;
        padding-bottom: 10px;
        max-width: 800px;
        overflow: scroll;
    }
    & li {
        min-width: fit-content;
        border: 1px solid #eee;
        background: #fff;
        display: flex;
        align-items: center;
        margin: 5px;
        border-radius: 3px;
        padding: 10px;
        box-sizing: border-box;
        font-size: 1.2rem;
        position: relative;
        overflow: hidden;
    }
   
    & .img{
        height: 30px !important;
        width: 30px;
        margin-right: 5px;
        border-radius: 100%;
        overflow: hidden;
        position: relative
    }
    & li img{
        height: 30px !important;
        width: 30px;
        object-fit: cover;
    }
    @media(max-width: 600px){
        & li {
           padding: 10px;
           font-size: 14px;
        }
    }
`

const Form = (props)=>{
    const { dispatch, state: { error, loading,  } = {} } = props
    const [ collectionDetail , setCollectionInfo ] = useState({
        name: '',
        description: '',
    })
    const { name, description } = collectionDetail


    const eventHandler = (e)=>{
        const { value, name } = e.target
        if(e.type === 'submit' && ( name && description ) ){
            e.preventDefault()
            dispatch(_createCollection(collectionDetail)) 
            setCollectionInfo({
                ...collectionDetail,
                name: '',
                description: '',
            })  
        }
        else{
            setCollectionInfo({
                ...collectionDetail,
                [name]: value
            })
        }            
    }


    return(
        <form onSubmit={eventHandler} >
            <div className='field'>
                {
                    true &&
                    <small className='red-t'>{error}</small>
                }
                <input 
                    required
                    type='text' 
                    name='name'
                    value = { name } 
                    onChange={eventHandler}
                    placeholder='Collection title' 
                    className='borderless form-fix' 
                    disabled={`${loading ? 'disabled' : ''}` }
                />
            </div>
            <div className='field'>
                <textarea  
                    required
                    name='description' 
                    value={ description }
                    onChange={eventHandler}
                    placeholder='Short description ' 
                    className='borderless form-fix' 
                    rows='2'
                    disabled={`${loading ? 'disabled' : ''}` }
                />
            </div><br />
            <div className='field'>
                <PrimaryButton 
                type='submit' 
                background='var(--primary-color)'
                disabled={`${loading ? 'disabled' : ''}` }
                >Create album</PrimaryButton>
            </div>
        </form>
    )
}


export const CategoryList = (props)=>{
    const { payload, name:title, photos, _id, handleCollectionToAdd } = props || {}
    const length = photos ? photos.length : null
    // Return the very first image in the collection as cover because its more likely to not change
    const albumCover = length > 1 ? photos[ length - 1 ].url : null
    const useTitle = shortenText(title,3,'...')
    const imageExistsInCollection = isIncludedInArray( photos, payload)
  
    

    return(
        <li 
            onClick={handleCollectionToAdd} 
            data-category={title} 
            data-category-id={_id} 
            className={`${ imageExistsInCollection ? 'added': ''} link mat shadow`} 
            key={randID()}>
                {
                    albumCover && 
                    <div 
                        onClick={handleCollectionToAdd} 
                        data-category={title} 
                        data-category-id={_id}  
                        className='img'>
                    <img 
                        onClick={handleCollectionToAdd} 
                        data-category={title} 
                        data-category-id={_id}  
                        src={albumCover} 
                        height='30' 
                        width='30' 
                        alt={'cover'} />
                    </div>
                }
                {useTitle} &nbsp;
                {length}
        </li>
    )
}

// Main Component
const AddToCollection = props =>{
    const { 
        postId, 
        type,
        withCollection, 
        handleFixHeight, 
        dispatch, 
        collection: { 
            collections, 
            loading 
        } = {},
        user: {
            authenticatedUser,
            // guestUser
        } = {}
    } = props
    const { docs } = collections || {}
    const { username: authUserName } = authenticatedUser || {}
    // const { username: guestUserName } = guestUser || {}
    const hasDocs = docs ? docs.length : null
    const [ isFormVisible, setIsFormVisible ] = useState(false)

    useEffect(()=>{
        setIsFormVisible(
            type === 'create' ? true : false
        )
    }, [type])
    // Fetch collections
    useEffect(()=>{
        if( !hasDocs && withCollection ){
            _fetchCollection(authUserName, dispatch)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    



    // Adjust modal height responsively
    const handleEvent = ()=>{
        setIsFormVisible(true)
        handleFixHeight()
    }

    // Handle real time refresh to enable highlighting selected category
    const [ refresh, setRefresh ] = useState(false)
    
    const handleCollectionToAdd = (e)=>{
        const selectedCategory = getAttribute(e.target, 'category-id')
        const dispatchPayload = {
            albumId: selectedCategory,
            postId
        }
        // Add image to collection
        dispatch (_addPhotoToCollection(dispatchPayload) )
        setRefresh(!refresh)
    }




    return(
        
        <StyledForm>
            {
                // Hide the "Add to collection buttons" if there is no payload to add
                true ? 
                <CollectionList>
                   <div className='margin-bottom-10 categories'>
                        {
                            hasDocs && !loading ?
                            docs.map(album=>{
                                return(
                                    <CategoryList 
                                    refresh={refresh} 
                                    {...album} 
                                    payload={ postId } 
                                    handleCollectionToAdd={handleCollectionToAdd} 
                                    key={randID()} />
                                )
                            }) 
                            : 
                            loading ? <InfiniteLoader /> 
                            :
                            <h4 className='grey-t text-center margin-centered'>You have not created any collections</h4> 
                        } 
                    </div>    
                    {
                        hasDocs ?
                        <NavLink 
                            className='blue-t'
                            to={`/@${authUserName}/collection`}>See all your collections...
                        </NavLink>
                        : null
                    } 
                    {
                        !isFormVisible && 
                        <>
                            <PrimaryButton 
                            margin='10px auto'
                            onClick={handleEvent} 
                            background='var(--primary-color)'
                            >Create new album instead</PrimaryButton> 
                        </>
                    }
                </CollectionList>
                : null
            }
            
            {
                isFormVisible &&  
                <Form  {...props}/>
               
            }            
        </StyledForm>
    )
}



const mapStateToProps = (state) => ({
    collection: state.collection,
    user: state.user

})
const mapDispatchToProps = (dispatch)=>{
    return{
        dispatch: action =>{dispatch(action)}
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddToCollection)