import React from 'react'
import getAttribute from '../../../utilities/getAttribute'
import randID from '../../../utilities/randID'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { clearMessage } from '../../../container/redux/actions/alert-actions/alert-action-creator';

const StyledAlert = styled.div`    
    position: fixed;
    bottom: 20px;
    padding: 20px;
    color: #000;
    font-weight: 600;
    background: #fff;
    border-radius: 3px;
    min-width: 50%;
    z-index: 9999999;
    box-shadow: 0px 5px 20px rgba(0,0,0,.08);
    transition: .5s;
    & .close {
        position: absolute;
        top: -0px;
        right: 5px;
        font-size: 30px;
        color: #aaa;
    }
    & .close:hover{
        color: #222
    }

    @media(max-width: 768px){
        min-width: 80%;
    }
}
`


const Alerts = (props)=>{
    const { dispatch, state: { messages } = {} } = props


    const cleanMessage = (e)=>{
        const index = getAttribute(e.target, 'index')
        dispatch(clearMessage(index))           
    }
    

    return(
        ReactDOM.createPortal(
            <>
                {
                    messages ? 
                    < >
                        {
                            messages.map( ( item, index) =>{
                                const { message, id } = item
                                return(
                                    <StyledAlert style={{bottom: ( index * 70 )  + 8 }} className='alert' key={randID()}>
                                        <span data-index={id} onClick={cleanMessage} className='link close'>&times;</span> 
                                        {message}
                                    </StyledAlert>
                                )
                            })
                        }
                    </>
                    : null
                }
            </>,
            document.getElementById('alertsPortal')
        )    
    )
}




const mapStateToProps = (state) => ({
    state: {...state.alerts}
})

const mapDispatchToProps = (dispatch)=>{
    return{
        dispatch: action =>{dispatch(action)}
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Alerts)