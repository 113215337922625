/* eslint-disable no-unused-vars */

// Add collection
export const addCollection = ( payload, collection )=>{
    let { docs, totalDocs } = collection
    const newTotalDoc = totalDocs + 1;
    const newCollection = [ payload, ...docs ]
    Object.assign(collection, { docs: newCollection, totalDocs: newTotalDoc })

    return collection
}


// Remove collection 
export const removeCollection = (id, collection )=>{
    if( id ){
        let { docs, totalDocs } = collection
        const newTotalDocs = totalDocs - 1;
        const newDoc = docs.filter( c => c._id !== id )
        Object.assign(collection, { docs: newDoc, totalDocs: newTotalDocs})
    }

    return collection
}