import React, { useState } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { AuthWrapper, StyledAuthForm } from './auth-style';
import { AuthBackgroundstyles } from './login'
import MessageAlert from '../widget/messages-alert/message';
import { _forgotPassword } from '../../container/redux/services/user.service';





const ForgotPasswordForm = ({eventHandler,extra, state: { email } = {}, })=>{
    const { tokenSent, loading, error } = extra


    return(
        <StyledAuthForm className='forgotPassword'>
            <form onSubmit={eventHandler}>
                <div className='centered-text form-head'>
                    <h2 className='auth-title'>Enter your email address</h2>
                </div>
                <div className='no-padding divider'></div>
                <div className='body'>
                   {    error &&
                        error.map( e =>{
                            const { msg } = e 
                            return(
                                <MessageAlert 
                                    icon='info' 
                                    type='error' 
                                    title='Error' 
                                    message={msg} 
                                />
                            )
                        })
                   }
                   {
                       tokenSent && 
                       <MessageAlert 
                            icon='check' 
                            type='success' 
                            title='Token sent successfully!' 
                            message={'Please check your email. It might take a while to deliver, do check the spam folders too'} 
                        />
                   }
                    <div className='field'>
                        <input 
                            onChange={ eventHandler } 
                            value={ email } 
                            type='email' name='email' 
                            placeholder='Email address' 
                            className='form-fix' 
                            required
                        />
                    </div>
                    <button 
                        type="submit" 
                        className='blue auth-btn full-width'
                        disabled = { tokenSent || loading  ? 'disabled' : null}
                    >Send reset link</button>
               </div>
               <div className='no-padding divider mt-30'></div>
                <div className='centered-text form-foot'>
                    <NavLink className='blue-t' to='/login'>Login Instead?</NavLink>
                </div>
            </form>
        </StyledAuthForm>
    )
}

const ForgotPassword = (props)=>{
    const { 
        dispatch,
        state: {
            loading,
            tokenSent,
            error
        } = {}
    } = props

    const [ resetDetail, setLoginDetail ] = useState({
        email: '',
    })
    

    const eventHandler = (e)=>{
        e.preventDefault()
        const { value, name } = e.target
        setLoginDetail({
            ...resetDetail,
            [name] : value
        })
        if(e.type === 'submit'){
            dispatch(_forgotPassword( resetDetail ))
        }
    }
   
    return(
        <>
            <AuthWrapper style={AuthBackgroundstyles} className="forgot-pass-wrapper">
                <div className="grid align-c padded-20 demo">
                    <h1>Best place to find awesome collections of stock photos from around Africa</h1>
                    <p>Join over <b>20,000</b> creative African photographer and share memories! </p>
                </div>
                <ForgotPasswordForm  
                    eventHandler={eventHandler} 
                    state={resetDetail} 
                    error={error}
                    extra={{...error, tokenSent, loading}}
                />
            </AuthWrapper>
        </>
    )
}



const mapStateToProps = state =>{
    return{
        state: state.account
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        dispatch: (action)=>{
            dispatch(action)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)