import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import PhotoLocation from '../photo-action/location';
import PrimaryButton from '../buttons/buttons';

const StyledAuthorPill = styled.li`
    flex: 3;
    align-items: center;
    color: ${ props => props.inverted ? '#222' : '#fff' };
    margin: 0px;
    &  .author-detail{
        margin: 0px
    }
    & img{
        height: 30px;
        width: 30px;
        border-radius: 100%;
        margin-left: 5px !important;
        padding: 2px;
        border: ${ props => props.plain ? 'none !important' : props.inverted ? '1.5px solid #222' : '1.5px solid #fff'  };
    } 
` 



const AuthorPill = (prop)=>{
    const { 
        photographer, 
        image, 
        location, 
        inverted, 
        plain,
        follow,
        username
    } = prop 


    return(
        <>
            <StyledAuthorPill plain={plain} inverted={inverted} className='no-wrap grid author'>
                <img src={image} alt={`${photographer}'s avatar`}/>
                <span className='no-margin grid col'>
                    <Link to={`/@${ username } `} className='no-margin'>
                        <p className='author-detail'>{photographer}</p>
                    </Link>
                    {
                        location && 
                        <PhotoLocation location={location} />
                    }
                </span>
            </StyledAuthorPill>
            {
                follow ? 
                    <Link to={`/@${ username } `}>
                        <PrimaryButton bold="600">
                        {follow}
                    </PrimaryButton>
                    </Link>
                : null
            }
        </>
    )
}

export default AuthorPill