import React from 'react'
import { Route, Redirect } from "react-router-dom"


export const ProtectedRoute = ( {component: Component, ...rest }) => {
    const isLoggedIn = localStorage.getItem('userToken')


    return(
        <Route 
            {...rest}
            render = {props =>{
                if( isLoggedIn ) {
                    return <Component {...props} />
                }else{
                    return <Redirect to={
                        {
                            pathname: "/login",
                            state : props.location
                        }
                    }/>
                }
            }}
        />
    )
}