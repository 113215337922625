import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components';
import randID from '../../utilities/randID';
import PrimaryButton from '../widget/buttons/buttons';
import getAttribute from '../../utilities/getAttribute';
import Modal from '../widget/modals/modal';
import { 
    _deleteAccount, 
    _updateAccount, 
    _changePassword 
} from '../../container/redux/services/user.service'
import UpdateForm from '../widget/account-update/updateForm';


const StyleAbout = styled.div`

    & .about-tab{
        padding: 20px;
        margin-top: 15px;
        box-shadow: 0px 5px 5px rgba(0,0,0,.05);
        border-radius: 3px;
    }  
    & .about-tab.danger{
        border: 1px solid var(--red);
    }
    & .no-shadow.about-tab{
        box-shadow: none
    }
    & .about-tab > * {
        padding: 15px 20px !important;
    }
    & .about-tab > *{
        margin: 0px;
    }
    & .about-tab h3{
        border-bottom: 1px solid #eee;
    }
    & .about-tab p {
        line-height: 1.5;
        font-size: 16px
    }
    & .about-tab .interests .interest{
        font-size: 16px;
        background: #fafafa;
        border-radius: 3px;
        padding: 5px 10px;
        margin-left: 0px;
    }
    & .about-tab .socials .social-link{
        box-shadow: var(--soft-shadow);
        padding: 5px 10px;
    }
    @media(max-width: 600px){
        font-size: 14px;
        & .about-tab{
            padding: 20px 5px 
        }
    }
`

const About = (props)=>{
    const { 
        currentUser, 
        isAuth,  
        dispatch ,
        state, 
    } = props || {}
    
    const { message, loading, error } = state || {}
    const { interests, twitter, instagram, bio } = currentUser || {}
    const socials = [
        { instagram }, { twitter }
    ]
    



    
    

    const [ action, setAction ] = useState({
        editAccount: false,
        accountDelete: false,
    })
    const { notice, editAccount, accountDelete } = action

    useEffect(()=>{
        message && setAction({
            ...action,
            notice: message
        })
        error && setAction({
            ...action,
            error: error
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    const handleEvent = (e)=>{
        const name = getAttribute(e.target, 'action')
        if ( name === 'edit'){
            setAction({
                ...action,
                editAccount: true,
                accountDelete: false
            })
        }else if ( name === 'delete'){
            setAction({
                ...action,
                editAccount: false,
                accountDelete: true
            })
        }else if( name === 'confirmDelete'){
            dispatch(_deleteAccount())
        }
    }
    const resetModalState = ()=>{
        setAction({
            ...action,
            accountDelete: false,
            editAccount: false,
            notice: null
        })
    }

    // Dispatch update
    const handleUpdateEvent = (payload, type)=>{
        const { 
            name, 
            email, 
            location, 
            interests, 
            instagram, 
            twitter, 
            website, 
            bio, 
            oldPassword, 
            newPassword 
        } = payload

        if(type){
            dispatch(_updateAccount({
                name, 
                email, 
                location, 
                interests, 
                instagram, 
                twitter, 
                website, 
                bio,
            }))
        }else{
            dispatch(_changePassword({
                oldPassword,
                newPassword
            }))
        }
    }

    
    return(
        <>
            <StyleAbout className='tab-content about-tab'>
                {
                    currentUser && 

                <div className=' about-wrapper container'>
                    <div className='no-shadow about-tab'>
                        <h3>Description</h3>
                        <p>{bio || 'Nill'}</p>
                    </div>

                    <div className='about-tab'>
                        <h3>Interests</h3>
                        <ul className='no-padding grid interests'>
                            {
                                interests ?
                                interests.map((item)=>{
                                    return (
                                        <NavLink key={randID()} className=' cap interest' to={`photos/${item}`}>
                                            {item}
                                        </NavLink>
                                    )
                                })
                                : 'Nill'
                            }
                        </ul>
                    </div>
                    <div className='about-tab'>
                        <h3>Social</h3>
                        <ul className='no-padding grid socials'>
                            {
                                socials ?
                                socials.map( item =>{
                                    return(
                                        Object.entries(item).map(element =>{
                                            const [ site, handle ] = element
                                            return(
                                                <li className='grid social-link' key={randID()}> 
                                                    <span className='cap'> <i className={`fa fa-${site}`}></i> </span> 
                                                {
                                                    handle ?  
                                                    <a className='bold-text-1' href={`https://${site}.com/${handle}`}>{ handle }</a> : 
                                                    <p>Not Available</p>
                                                }
                                                </li>
                                            )
                                        })
                                    )
                                })
                                :
                                'Nill'
                            }
                        </ul>
                    </div>
                    {
                        isAuth ?
                        <div className='danger about-tab'>
                            <h3>Account misc</h3>
                            <ul className='no-padding grid interests'>
                                <PrimaryButton
                                    onClick={handleEvent}
                                    padding='10px 20px'
                                    data-action='edit'
                                >
                                    Edit Account
                                </PrimaryButton>
                                <PrimaryButton
                                    onClick={handleEvent}
                                    padding='10px 20px'
                                    background='var(--red)'
                                    data-action='delete'
                                >
                                    Delete Account
                                </PrimaryButton>
                            </ul>
                        </div>
                        : null
                    }
                </div>
                }
            </StyleAbout>
            {/* Account delete form  */}
            {
                accountDelete && isAuth &&
                <Modal
                    title="Delete Account?"
                    resetModalState={resetModalState}
                >
                    <div className='grid col centered centered-text'>
                        <span>This action cannot be undone, proceed?</span>
                        <PrimaryButton 
                            background='var(--red)'
                            onClick={handleEvent}
                            data-action='confirmDelete'
                            disabled={ loading ? 'disabled' : null}
                        >
                            {
                                loading ? 'Deleting...' : ' Proceed'
                            } 
                        </PrimaryButton>
                        {
                            notice &&
                            <p className='centered-text green-t' key={randID()}>{notice}</p>
                        }
                        {
                            error &&
                            <p className='centered-text red-t' key={randID()}>{error}</p>
                        }
                    </div>
                </Modal>
            }
            {/* Account Edit form */}
            {
                editAccount && isAuth &&
                <Modal
                    minimize
                    adjustHeight
                    title="Update Account"
                    resetModalState={resetModalState}
                >
                    <div className='no-padding grid col centered'>
                        <UpdateForm 
                            handleEvent={handleUpdateEvent} 
                            data= {currentUser}
                            waiting={loading}  
                            state={{error, notice}}                       
                        />
                    </div>
                </Modal>
            }
        </>
    )
}



const mapStateToProps = state =>{
    return{
        state: state.account
    }
}
const mapDispatchToProps = (dispatch) => ({
    dispatch: action =>{
        dispatch(action)
    } 
})

export default connect(mapStateToProps, mapDispatchToProps)(About)