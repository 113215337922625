import * as actions from '../actions/auth-actions/auth-action'
import * as accountActions from '../actions/acount-actions/acount-action'

const INITIAL_STATE = {

}

const AccountReducer = ( state = INITIAL_STATE, action ) => {
    const { type, payload } = action

    
    switch(type){

        // FORGOT PASSWORD
        case actions.FORGOT_PASSWORD_REQUEST:
            return { 
                loading: true 
            } 
        case actions.FORGOT_PASSWORD_SUCCESS:
            return {
                tokenSent: true, 
            }
        case actions.FORGOT_PASSWORD_FAILURE:
            return {
                error: payload 
            }

        // RESET PASSWORD
        case actions.RESET_PASSWORD_REQUEST:
            return Object.assign( state, { 
                loading: true 
            })
        case actions.RESET_PASSWORD_SUCCESS:
            return Object.assign( state, { 
                loading: false, 
                valid: true,
                error: null 
            })
        case actions.RESET_PASSWORD_FAILURE:
            return Object.assign( state, { 
                loading: false, 
                valid: false,
                error: payload
            })

        // DELETE ACCOUNT
        case accountActions.DELETE_ACCOUNT_REQUEST:
            return {
                loading: true
            }
        case accountActions.DELETE_ACCOUNT_SUCCESS:
            localStorage.removeItem('userToken')
            window.history.push('/')
            return { 
                loading: false, 
                message: payload
            }
        case accountActions.DELETE_ACCOUNT_FAILURE:
            const { 0: { msg } = {} } = payload || {}
            return{
                loading: false,
                error: msg,
                message: null
            }

            // UPDATE ACCOUNT
        case accountActions.UPDATE_ACCOUNT_REQUEST:
            return{
                loading: true
            }
        case accountActions.UPDATE_ACCOUNT_SUCCESS:
            window.location.reload()
            return{
                loading: false,
                error: null,
                message: payload
            }
        case accountActions.UPDATE_ACCOUNT_FAILURE:
            const { 0: { msg: updateMessage } = {} } = payload || {}
            return{
                loading: false,
                error: updateMessage,
                message: null
            }

        // PASSWORD UPDATE
        case accountActions.CHANGE_PASSWORD_REQUEST: 
            return{
                loading: true
            }
        case accountActions.CHANGE_PASSWORD_SUCCESS:
            const { msg: successMessage } = payload
            window.location.reload()
            return{
                loading: false,
                message: successMessage,
                error: null
            }
        case accountActions.CHANGE_PASSWORD_FAILURE:
            const { 0: { msg: errorMessage } = {} } = payload || {}
            return{
                loading: false,
                error: errorMessage,
                message: null
            }

        // CHANGE PROFILE IMAGE
        case accountActions.AVATAR_UPLOAD_REQUEST:
            return{
                loading: true
            }
        case accountActions.AVATAR_UPLOAD_SUCCESS: 
            window.location.reload()
            return{
                message: payload,
                loading: false,
            }
        case accountActions.AVATAR_UPLOAD_FAILURE: 
            return{
                message: payload,
                loading: false,
            }
        default : 
            return state
    }
}

export default AccountReducer