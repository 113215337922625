import * as actions from "../actions/collections-action/collection-action"
import { addCollection, removeCollection } from "./helpers/helpers";




const initialState = {
    collections: null,
    collectionView: null,
    loading: false,
    error: false,
}




const collectionsReducer = ( state = initialState, action )=>{
    const { type, payload } = action

    switch(type){
        case actions.ADD_TO_COLLECTION:
            return{
                ...state,
                loading: true,
                error: ''

            }
        case actions.ADD_TO_COLLECTION_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                collections: payload,
            }

        case actions.ADD_TO_COLLECTION_FAILURE:
            return{
                ...state,
                loading: false,
                error: payload,
                collections: state.collections
            }
        case actions.CREATE_COLLECTION:
            return{
                 ...state,
                error: false,
                loading: false,         
            }

        case actions.CREATE_COLLECTION_SUCCESS:
            const { album } = payload
            return{
                ...state,
                error: false,
                loading: false,
                collections: addCollection(album, state.collections)
            }
        case actions.CREATE_COLLECTION_FAILURE:
            return{
                ...state,
                error: true,
                loading: false,       
            }
        case actions.FETCH_COLLLECTION_REQUEST:
            return{
                ...state,
                loading: true,
                account: payload
            }
        case actions.FETCH_COLLLECTION_SUCCESS:
            const { docs, ...rest } = payload
            return{
                ...state,
                loading: false,
                collections: {
                    docs,
                    ...rest                    
                }
            }
        case actions.FETCH_COLLLECTION_FAILURE:
            return{
                ...state,
                error: true,
                loading: false,
            }

        case actions.DELETE_COLLECTION:
            const newCollection = payload ? payload.target : null;
            return{
                ...state,
                collections: removeCollection( newCollection, state.collections)
            }
        case actions.GET_COLLECTION_REQUEST: 
            return{
                ...state,
                loading: true,
                error: false
            }
        case actions.GET_COLLECTION_SUCCESS: 
            return{
                ...state,
                loading: false,
                error: false,
                collectionView: payload
            }
        case actions.GET_COLLECTION_FAILURE: 
            return{
                ...state,
                error: true,
                loading: false,
                collectionView: null
            }
        default: 
            return state
    }

}


export default collectionsReducer