import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { AuthWrapper, StyledAuthForm } from './auth-style';
import { AuthBackgroundstyles } from './login'
import MessageAlert from '../widget/messages-alert/message';
import { _resetPassword } from '../../container/redux/services/user.service';
import Void from '../widget/void-content/void';
import Loader from '../widget/loader/loader';
import sendMessageWithClear from '../../utilities/sendMessageAlert';





const ResetPasswordForm = ({eventHandler,error, state, })=>{
    const { oldPassword, newPassword } = state || {}


    return(
        <StyledAuthForm className='forgotPassword'>
            <form onSubmit={eventHandler}>
                <div className='centered-text form-head'>
                    <h2 className='auth-title'>Create a new password</h2>
                </div>
                <div className='no-padding divider'></div>
                <div className='body'>
                   {    error &&
                        <MessageAlert 
                            icon='info' 
                            type='error' 
                            title='Error' 
                            message={error} 
                        />
                   }
                    <div className='field'>
                        <input 
                            onChange={ eventHandler } 
                            value={ oldPassword } 
                            type='password' 
                            name='oldPassword' 
                            placeholder='Old password' 
                            className='form-fix' 
                            required
                        />
                    </div>
                    <div className='field'>
                        <input 
                            onChange={ eventHandler } 
                            value={ newPassword } 
                            type='password' 
                            name='newPassword' 
                            placeholder='New password' 
                            className='form-fix' 
                            required
                        />
                    </div>
                    <button type="submit" className='blue auth-btn full-width'>Reset Password</button>
               </div>
            </form>
        </StyledAuthForm>
    )
}

const ResetPassword = (props)=>{
    const { 
        dispatch,
        state,
        history,
        location: { pathname, search } = {}
    } = props
    const {  
        error, 
        valid,
        loading,
    } = state || {}
    
    const [ resetDetail, setLoginDetail ] = useState({
        oldPassword: '',
        newPassword: ''
    })   
    const [ nullToken, setNullToken ] = useState(false)

    // Strip URL of token
    useEffect(()=>{
        // reset the url
        window.history.pushState( {}, ' ', pathname )
        const tkn = search.split('=')[1]
        if( tkn ){
            dispatch(_resetPassword(resetDetail))
        }
        else{
            setNullToken(true)
        }
    }, [])
    // Validating phase
    const [ validating, setValidating ] = useState(true)
    useEffect(()=>{
        setValidating(false)
        if ( valid ){
            sendMessageWithClear(dispatch, 'Token validated! Returning to login...')
            setTimeout(()=>{
                history.push('/login')
            }, 2000)
        }
    },[])

    

    const eventHandler = (e)=>{
        e.preventDefault()
        const { value, name } = e.target
        setLoginDetail({
            ...resetDetail,
            [name] : value
        })
        if(e.type === 'submit'){
            dispatch( _resetPassword( resetDetail ))
        }
    }
   
    return(
        <>
            <AuthWrapper style={AuthBackgroundstyles} className="forgot-pass-wrapper">
                <div className="grid align-c padded-20 demo">
                    <h1>Best place to find awesome collections of stock photos from around Africa</h1>
                    <p>Join over <b>20,000</b> creative African photographer and share memories! </p>
                </div>

                {/* ACTIONS */}
                {
                    !validating && valid && 
                    <ResetPasswordForm  
                        eventHandler={eventHandler} 
                        state={resetDetail} 
                        error={error}
                    />
                }
                {
                    ( validating || !valid || error || nullToken  ) &&
                    <div className=' white padded-20'>
                        {
                            !validating && !valid && !nullToken && 
                            <Void 
                                title={error}
                                description={`The token you supplied is invalid or might have expired `}
                                link={{
                                    route: '/forgot-password',
                                    text: 'Generate a new one'
                                }} 
                            />
                        }
                        {
                            validating &&
                            <Loader message='Please wait, validating your token...' />
                        }
                        {
                            error &&
                            <Void 
                                title={error}
                                description='Could not complete your validation...'
                            />
                        }
                        {
                            !validating && nullToken &&
                            <Void 
                                title='No token found!' 
                                description='Please make sure youre using the link sent to your email' 
                            />
                        }
                    </div>
                }
            </AuthWrapper>
        </>
    )
}



const mapStateToProps = state =>{
    return{
        state: state.account
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        dispatch: (action)=>{
            dispatch(action)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)