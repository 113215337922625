import React from 'react';
import styled from 'styled-components';
import Landing from '../landing/landing';
import Gallery from '../gallery/gallery';
import TagNav from '../Common/tag-nav/tagNav';



const StyledHome = styled.section``
const Home = ({location: { pathname: route } = {}})=>{

    return(
         <StyledHome className='home-wrapper'>
            <Landing />
                <TagNav route={route} override />
            <Gallery />
       </StyledHome>
    )
}



export default Home