import * as actions from '../actions/likes-actions/likes-actions'



const initialState = {
    likedPhotos: [],
    adding: false,
    error: '',
}



const likesReducer = ( state = initialState, action )=>{
    const { type, payload } = action

    switch(type){
        case actions.FETCH_PHOTO_BEGIN:
            return{
                ...state,
                loading: true
            }
        case actions.FETCH_PHOTO_SUCCESS:
            return {
                ...state,
                loading: false,
                likedPhotos: payload
            }
        case actions.FETCH_PHOTO_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case actions.LIKE_SUCCESS:
            return {
                ...state,
                // likedPhotos: [ payload, ...state.likedPhotos ] 
            }
        case actions.UNLIKE_SUCCESS:
            return {
                ...state,
                // likedPhotos:  state.likedPhotos.filter( l => l !== payload )
            }
        default: 
            return state
    }

}


export default likesReducer