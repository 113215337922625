import * as actions from "./photo-action"
import axios from "axios";


export const fetchPhotoBegin = (payload)=>{
    return{
        type: actions.FETCH_PHOTO_BEGIN,
        payload
    }
}

export const fetchPhotoSuccess = (payload)=>{
    return{
        type: actions.FETCH_PHOTO_SUCCESS,
        payload
    }
}


export const fetchPhotoFailure = (payload)=>{
    return{
        type: actions.FETCH_PHOTO_FAILURE,
        payload: payload
    }
}


export const fetchUserPhoto = (payload)=>{
    return{
        type: actions.FETCH_USER_PHOTO_REQUEST,
        payload
    }
}


export const fetchUserPhotoSuccess = (payload)=>{
    return{
        type: actions.FETCH_USER_PHOTO_SUCCESS,
        payload: payload
    }
}

export const fetchUserPhotoFailure = (payload)=>{
    return{
        type: actions.FETCH_USER_PHOTO_FAILURE,
        payload: payload
    }
}

export const updatePhotoList = (payload)=>{
    return{
        type: actions.UPDATE_PHOTO_LIST,
        payload
    }
}