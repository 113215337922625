import React from 'react';
import styled from 'styled-components';

const StyledIntro = styled.div`
    padding: 10px 0px 20px 0px;
    & p{
        margin-top: 8px;
    }
`

const TabIntro = ({title, description})=>{

    return(
        <StyledIntro className='centered-text title'>
            <h1>{title}</h1>
            <p>{description}</p>
        </StyledIntro>
    )
}

export default TabIntro

