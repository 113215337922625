import * as userActions from '../actions/user-action/user-action'

const INITIAL_STATE = {
    
}

const UserReducer = ( state = INITIAL_STATE, action )=>{
    const { type, payload } = action
    switch( type ){
        case userActions.GET_USER_REQUEST:
            return{
                loading: true
            }
        case userActions.GET_USER_SUCCESS:
            return{
                ...state,
                loading: false,
                error: null,
                authenticatedUser: payload,
                isLoggedin: true
            }
        case userActions.GET_USER_FAILURE:
            return{
                loading: false,
                error: payload,
                authenticatedUser: null,
                isLoggedin: false
            }

        // GET OTHER USERS PROFILE
        case userActions.USER_PROFILE_REQUEST:
            return{
                ...state,
                loading: true,
                error: null
            }
        case userActions.USER_PROFILE_SUCCESS:
            return{
                ...state,
                loading: false,
                error: null,
                guestUser: payload
            }
        case userActions.USER_PROFILE_FAILURE:
            const { 0: { msg } = {} } = payload || {}
            return{
                ...state,
                loading: false,
                error: msg,
                guestUser: null
            }
        case userActions.UPDATE_FOLLOW_LIST:
            return{
                ...state,
                authenticatedUser: {
                    ...state.authenticatedUser,
                    following: state.authenticatedUser.following + payload
                }
            }
        case userActions.FOLLOW_USER_UPDATE:
            return{
                ...state,
                guestUser: {
                    ...state.guestUser,
                    followers: state.guestUser.followers + payload
                }
            }
        
        default: 
            return state

    }
}



export default UserReducer