/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import styled from 'styled-components';
import { _likePhoto } from '../../../container/redux/services/photo.service';
import sendMessageWithClear from '../../../utilities/sendMessageAlert';




const StyledLike = styled.svg`
    fill: transparent;
    stroke: ${props => props.currentlyViewingPhoto ? '#222' : '#fff'};
    stroke-width: 1.5;
    position: relative;
    cursor: pointer;
    padding: 3px;
    @media(max-width: 768px){
        stroke: #222;
    }

`
const LikeButton = (props)=>{
   const { 
       payload, 
       currentlyViewingPhoto, 
       like: { 
           likedPhotos 
        } = {} , 
        account: {
            isLoggedin,
            authenticatedUser: {
                _id: userId
            } = {}
        } = {},
        history,
        dispatch 
    } = props
    
    const { photo: { _id: photoID } ={}, isLiked } = payload
    const { docs, totalDocs } = likedPhotos || {}



    // Enable unlike to be done in background
    const [ rapidLike, setRapidLike ] = useState(false)
    const eventHandler = ()=>{
        if( isLoggedin ){
            if( isLiked ){
                setRapidLike(false)
            }else if(isLiked){
                setRapidLike(true)
            }
        }else{
            const { location: { pathname } } = history ||  {}
            sendMessageWithClear(dispatch, 'You must be logged in to like a photo!')
            history.push('/login', { pathname })
        }
        likeHandler()
    }  

    const likeHandler = ()=>{
        const actionType = isLiked ? 'unlike' : 'like'
        dispatch( _likePhoto( photoID, actionType ) )
    }
    const randomLikedPhotoLiker = docs && totalDocs ? docs[0].liker : {}
    


    
    // Switch between liked and unliked icons
    const style =  ( 
        ( isLiked || rapidLike ) 
        || 
        ( userId === randomLikedPhotoLiker ) 
    ) ? {
        fill: '#f00',
        stroke: '#f00'
    } : null


    return(
        <StyledLike
            currentlyViewingPhoto={currentlyViewingPhoto}
            style={style}
            title={ isLiked ? 'Remove from favourite' : 'Add to favourite' } 
            onClick={ eventHandler } 
            className='like'
            xmlns="http://www.w3.org/2000/svg" 
            width="30" 
            height="30" 
            viewBox="-1 0 30 30">
            <path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z" />
        </StyledLike>
    )
}



const mapStateToProps = (state) =>{
    return{
        like: state.like,
        account: state.user 
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        dispatch: (action)=>{
            dispatch(action)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter( LikeButton ))