/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'


import Modal from '../modals/modal';
import getAttribute from '../../../utilities/getAttribute'
import LikeButton from '../photo-action/like-button';

import AddToCollectionButton from '../photo-action/addToCollectionButton';
import AddToCollection from '../addToCollectionModal/addToCollectioModal'
import screenSize from '../../../utilities/checkScreenSize';
import View from '../../view-image/view'
import StyledPhoto from './photo.style'
import AuthorPill from './author';




const Photo = (props)=>{
    
    const { 
        location: { pathname } = {},
        payload, 
        currentlyViewingPhoto, 
        inViewMode, 
        related,
    } = props

    const { 
        author,
        isLiked,
        // ownPost,
        photo,
    } = payload || {} 

    const { 
        url, 
        // views, 
        caption, 
        // category, 
        // likes, 
        location,
        _id: postId  
    } = photo || {}
    const { avatarUrl, name, username, _id:userId } = author || {}

    // Routes
    const photoLink = caption ? `/images/${caption.split(' ').join('-').toLowerCase()}/${ postId }` : ''
    const currentPageRoute = pathname.split('/')[1]
    const originalRoute = `/${currentPageRoute.toString()}`

    // Handle Modal display
    const [ viewModal, setViewModal ] = useState({
        view: null,
        collectionModal: null,
    })
    useEffect(()=>{
    },[])

      

    const eventHandler = (e)=>{
        const name = getAttribute(e.target, 'modal-mode')

        // Toggle between collection modal and view modal
        if( name === 'collectionModal'){
            viewModal.collectionModal ? 
            setViewModal({
                ...viewModal,
                collectionModal: null,
            }) :
            setViewModal({
                ...viewModal,
                collectionModal: payload,
            })
        }else if( name === 'imagePreview' ){

            // Make sure no reoccuring modal window is open if it is already open, but still push the image to state to update the current UI
            if( !inViewMode ){
                viewModal.view ? 
                setViewModal({
                    ...viewModal,
                    view: null,
                }) :
                setViewModal({
                    ...viewModal,
                    view: payload,
                })
                 // Set the url to the url of the image displayed
                 window.history.pushState({}, '', photoLink )
            }
        }       
    }
    
   
    // Handle Height adjustment for collectionModal
    const [ fixHeight, setFixHeight ] = useState(false)
    const handleFixHeight = ()=>{
        setFixHeight(true)
    }

    
    // Reset all local state modal status
    const resetModalState = ()=>{
        setViewModal({
            collectionModal: null,
            view: null
        }) 
        setFixHeight(false)
        // Set the url to the url of the image displayed
        window.history.pushState({}, '', originalRoute )
    }

    // Enable image preview to be displayed in a modal on desktop devices only
    const { outerWidth } = screenSize()
    const extraClass = currentlyViewingPhoto ? 'currentPhoto' : 'neutral'
    const mobilePhoneView = ( 
        outerWidth <= 768 && 
        !currentlyViewingPhoto) || 
        ( 
            currentPageRoute === 'images' && 
            !currentlyViewingPhoto 
        )



    

    return(
            <>
                {
                    <StyledPhoto 
                        related={related}
                        title={ currentlyViewingPhoto ? caption : ''}  
                        inViewMode={inViewMode} 
                        className={`${extraClass}`}
                    >
                        
                        {
                            // Open photo in view route instead if these conditions are met. 
                            mobilePhoneView ?
                            <Link to={photoLink}>
                                <img 
                                    src={url} 
                                    alt={caption} 
                                    data-url={url}
                                    className='payload' 
                                    title={caption} 
                                />
                            </Link>
                            :
                            <img 
                                src={url} 
                                alt={caption} 
                                data-url={url}
                                className='payload' 
                                title={caption}  
                                data-modal-mode='imagePreview'  
                                onClick={eventHandler}
                            />
                        }
                        {
                            !related ?
                            <>
                                <ul className='no-wrap no-padding grid apart actions'>
                                    <AuthorPill 
                                        photographer={ name } 
                                        username={ username }
                                        image={ avatarUrl }
                                        location={location} 
                                    />
                                    <li className='no-wrap grid apart action'>
                                        <LikeButton 
                                            currentlyViewingPhoto={ currentlyViewingPhoto } 
                                            payload={ payload }  />
                                        <AddToCollectionButton 
                                            currentlyViewingPhoto={currentlyViewingPhoto} 
                                            handleEvent={eventHandler} 
                                        />
                                    </li>
                                </ul>
                                <span className='absolute backdrop'></span>
                            </>
                            : null
                        }
                    </StyledPhoto>
                }


                 {/* Modals */}
                 {
                    viewModal.collectionModal && 
                    <Modal
                        inViewMode={ inViewMode }
                        adjustHeight = {fixHeight}
                        resetModalState={resetModalState}
                        isOpen={viewModal.collectionModal}
                        title='Add image to collection'
                    >
                        <AddToCollection 
                            postId={ postId } 
                            handleFixHeight={handleFixHeight} 
                            withCollection
                        />
                    </Modal>
                }
                {
                    viewModal.view === payload &&
                    <Modal 
                        resetModalState={resetModalState} 
                        typePlain
                    >
                        <View
                            inViewMode={ true } 
                            photoId={ postId } />
                    </Modal>
                }
            </>

        )
}


export default withRouter(Photo)