import axios from 'axios';
import * as followActions from '../actions/user-engagements/userEngagement-action-creators'
import * as userActions from '../actions/user-action/user-action-creator'


import authHeader from '../../../utilities/authHeader'
import AxiosCall from '.';

export const _followUser = (userID)=>{
    return (dispatch)=>{
        dispatch(followActions.followUserRequest())
        const API_ENDPOINT = AxiosCall(`engage/follow/${userID}`)
        const MY_HEADERS = {
            Authorization: `Bearer ${authHeader()}`
        }
    

        const REQUEST_OPTIONS = {
            method: 'PATCH',
            headers: MY_HEADERS,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data: { msg } = {} } = {} } = response
            // UPDATE FOLLOW LIST
            dispatch(userActions.updateFollowList(1))
            dispatch(followActions.followUserSuccess({msg}))
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch(followActions.followUserFailure({msg}))
        })


    }
}

// unfollow
export const _unFollowUser = (userID)=>{
    return (dispatch)=>{
        dispatch(followActions.followUserRequest())
        const API_ENDPOINT = AxiosCall(`engage/unfollow/${userID}`)
        const MY_HEADERS = {
            Authorization: `Bearer ${authHeader()}`
        }
    

        const REQUEST_OPTIONS = {
            method: 'PATCH',
            headers: MY_HEADERS,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data: { msg } = {} } = {} } = response
            // UPDATE FOLLOW LIST
            dispatch(userActions.updateFollowList(-1))
            dispatch(followActions.followUserSuccess({msg}))
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch(followActions.followUserFailure({msg}))
        })


    }
}
