import * as actions from '../actions/user-engagements/userEngagement'

const INITIAL_STATE = {

}

const Engagement = ( state = INITIAL_STATE, action ) =>{
    const { type } = action
    switch(type){
        case actions.FOLLOW_USER_REQUEST:
            return{
                loading: true,
            }

        case actions.FOLLOW_USER_SUCCESS:
            return{
                loading: false,
            }
        case actions.FOLLOW_USER_FAILURE:
            return{
                loading: false,
            }
        default: return state
    }
}

export default Engagement