// FETCH PHOTOS
export const FETCH_PHOTO_BEGIN = "FETCH_PHOTO_BEGIN"

export const FETCH_PHOTO_SUCCESS = "FETCH_PHOTO_SUCCESS"

export const FETCH_PHOTO_FAILURE = "FETCH_PHOTO_FAILURE"

// FETCH PHOTOS
export const FETCH_USER_PHOTO_REQUEST = "FETCH_USER_PHOTO_REQUEST"

export const FETCH_USER_PHOTO_SUCCESS = "FETCH_USER_PHOTO_SUCCESS"

export const FETCH_USER_PHOTO_FAILURE = "FETCH_USER_PHOTO_FAILURE"

export const UPDATE_PHOTO_LIST = "UPDATE_PHOTO_LIST"