import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'


import Void from '../widget/void-content/void';
import Photo from '../widget/photo/photo';
import Related from '../widget/related-images/related-image';
import PhotoDetail from './viewModules/PhotoDetail';
import InfiniteLoader from '../widget/loader/infiniteLoader';
import Loader from '../widget/loader/loader';
import { _findPhotoMatch } from '../../container/redux/services/photo.service';
import PrimaryButton from '../widget/buttons/buttons';

import LikeButton from '../widget/photo-action/like-button';
import AddToCollectionButton from '../widget/photo-action/addToCollectionButton';




const StyledView = styled.div`
    & .photo-wrap{
        max-width: 800px;
        display: flex;
        justify-content: center;
        margin: auto;
    }
    min-height: 500px;
    height: fit-content;
    scroll-behavior: ${ props => props.data ? props.data.length <= 30 ? 'smooth' : '' : null};
    & .photo-misc{
        padding: 10px;
        box-sizing: border-box
    }
    & .photo-misc .title{
        text-transform: capitalize
    }
    
    & > * {
        margin: 0px auto !important;
    }  
    & .photo-head {
        border-bottom: 1px solid #eee;
        z-index: 99;
        background: #fff;
        position: sticky
    }
    & .photo-head .quick-actions button{
        padding: 5px 20px;
        display: flex;
        align-items:center;
        box-shadow: 0px 0px 3px rgba(0,0,0,.08); 
        background: white
    }
    & .photo-head .quick-actions button svg{
        height: 18px;
        stroke
    }
    & .photo-head .quick-actions button svg:last-child{
        fill: #000
    }
    & .related-section{
        margin: 30px;
    }
    & .related-section h3{
        padding: 20px
    }
    @media(min-width: 768px){
        & .photo-misc {
            padding: 10px 40px;
        }
        & .photo-misc .title{
            font-size: 30px 
        }
    } 
    @media(max-width: 500px){
        & .photo-misc .title{
            font-size: 18px 
        }
        & .photo-head {
            padding: 5px 8px !important
        }
    }
`



const View = (props)=>{
    
    const { 
        dispatch,
        inViewMode,
        ownProps: {
            photoId,
        } = {},
        state: { 
            loading,   
            message,
            match,
        } = {}, 
        match: { 
            params : { 
                id : currentPhotoId
            } = {}  
        },
    } = props
    // Get match photos 
    const photoToMatch = photoId ? photoId  : currentPhotoId
    const canViewPass =  match 
    const { photo:{ caption } = {} } = match || {}

    // Make sure screen view is started from the top on load or image change and begin finding match for photo ID
    const scrollToTop = useRef(null)
    useEffect(()=>{
        canViewPass && scrollToTop.current.scrollIntoViewIfNeeded()
        _findPhotoMatch( dispatch, photoToMatch )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ photoId, currentPhotoId ])

    const [ pos, setPos ] = useState()
    const handlePos = (offset)=>{
        setPos(offset)
    }
    useEffect(()=>{
        window.addEventListener('scroll', ()=>{
            handlePos(window.pageYOffset)
        } )
        return(
            window.removeEventListener('scroll', ()=>{
                handlePos(window.pageYOffset)
            } )
        )
    }, [])
    const actionTabStyle = pos > 100 && !inViewMode ? {
        top: '50px',
        padding: "5px 20px"
    } : {}

    
    
    return(
        <React.Fragment>
            <StyledView className="white view">
                {
                    loading && photoId === undefined ?
                    <>
                        <InfiniteLoader />
                        <Loader message='Loading, please wait...' />
                    </>
                    : 
                    loading && 
                    <InfiniteLoader />
                }

                {
                    canViewPass ?
                    <>
                        <div 
                            style={ actionTabStyle } 
                            ref={scrollToTop} 
                            className='photo-misc grid align-c apart photo-head'>
                            <h2 className='title'>{ caption }</h2>
                            {
                                pos > 100 ?
                                <div
                                    className="grid nowrap quick-actions">
                                    <PrimaryButton>
                                        <LikeButton payload={ match } />
                                    </PrimaryButton>
                                    <PrimaryButton>
                                        <AddToCollectionButton />
                                    </PrimaryButton>
                                </div>
                                : null
                            }
                        </div>
                        <div className='photo-wrap'>
                            <Photo 
                            payload={match} 
                            currentlyViewingPhoto={true} 
                            inViewMode={true} />
                        </div>
                        <PhotoDetail {...match}  />
                        <div className='no-padding related-section'>
                            <h3 className="margin-top-10 ">
                                Similar Photos
                            </h3>
                            <Related inViewMode={true}  />
                        </div>
                    </>
                    : null
                }
                {
                    !loading && message ?
                    <Void 
                        adjustposition 
                        title={message} 
                        description={'There was a problem completing your request'}
                    />
                    : null 
                }
            </StyledView> 
        </React.Fragment>
    )
}



const mapStateToProps = (state, ownProps ) =>{
    return{
        state: state.view,
        ownProps
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        dispatch: (action)=>{
            dispatch(action)
        }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(View))