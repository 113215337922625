export const  LOGIN_REQUEST = 'LOGIN_REQUEST'

export const  LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export const  LOGIN_FAILURE = 'LOGIN_FAILURE'

// REGISTER

export const  REGISTER_REQUEST = 'REGISTER_REQUEST'

export const  REGISTER_SUCCESS = 'REGISTER_SUCCESS'

export const  REGISTER_FAILURE = 'REGISTER_FAILURE'


// PASSWORDS

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_RERQUEST'

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'

export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

// PASSWORD RESET

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'

export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'


// LOG OUT
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'

export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

