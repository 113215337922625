import React from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Bolt } from '../../../assets/thunder.svg'
import randID from '../../../utilities/randID';


const StyledNavHeader = styled.div`
    background: #fff !important;
    padding: 0px 20px;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    z-index: 999;
    
    &.home-nav{
        position: sticky;
        top: 0px;
    }
    & > * {
        padding: 8px 0px;
    }
    & .icon svg{
        fill: #bbb
    }

    & ul {
        border-left: 1px solid #777;
        padding-left: 8px !important;
        overflow-x: auto;
        width: 100%;
        padding-bottom: 8px !important;
    }
    & ul::-webkit-scrollbar{
        height: 2px !important;
        display: none
    }
    & ul::-webkit-scrollbar-thumb{
        background: #eee !important;
    }
    & .tag{
        color: #777;
        font-size: 22px;
        padding: 5px;
        margin-right: 10px;
        letter-spacing: 2px;
        :hover{
            color: #555
        }
    }
    @media screen and (max-width: 600px){
        & .tag{
            font-size: 16px;
        }
    }
` 
const TagNav = ({route, override})=>{
    const mock = ['Africa', 'Field', 'Heartbreak', 'Education', 'Sport', 'Africa', 'Field', 'Heartbreak', 'Education', 'Sport', 'Africa', 'Field', 'Heartbreak', 'Education', 'Sport', 'Africa', 'Field', 'Heartbreak', 'Education', 'Sport']
    const restrictedRoute = [
        'home',
        'login',
        'join',
        ''
    ]
    const restrict = restrictedRoute.includes(route)
    const photos = useSelector( state =>{
        return state.photo.photos
    })

    
    return(
        <>
            {
                restrict && !override ? 
                null:
                override ? photos ?
                <StyledNavHeader className={`no-wrap grid align-c ${ route === ('/' || '') ? 'home-nav' : ''}`}>
                    <span className='icon'>
                        <Bolt />
                    </span>
                    <ul className='no-padding'>
                        {
                            mock.map( tag => {
                                return(
                                    <NavLink 
                                    className='tag' 
                                    to={`tags/${tag}`}
                                    key={randID()}>
                                        {tag}
                                    </NavLink>
                                )
                            })
                        }
                    </ul>
                </StyledNavHeader>
                : null
                :
                <StyledNavHeader className={`no-wrap grid align-c ${ route === ('/' || '') ? 'home-nav' : ''}`}>
                    <span className='icon'>
                        <Bolt />
                    </span>
                    <ul className='no-padding'>
                        {
                            mock.map( tag => {
                                return(
                                    <NavLink 
                                    className='tag' 
                                    to={`tags/${tag}`}
                                    key={randID()}>
                                        {tag}
                                    </NavLink>
                                )
                            })
                        }
                    </ul>
                </StyledNavHeader>
            }
        </>
    )
}


export default TagNav