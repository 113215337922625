import * as actions from "./collection-action"



// CREATE COLLECTIONS 

export const createCollection = ()=>{
    return{
        type: actions.CREATE_COLLECTION,
    }
}


export const createCollectionSuccess = (payload)=>{
    return{
        type: actions.CREATE_COLLECTION_SUCCESS,
        payload
    }
}


export const createCollectionFailure = (payload)=>{
    return{
        type: actions.CREATE_COLLECTION_FAILURE,
        payload
    }
}


// ADD TO COLLECTION


export const addToCollection = ()=>{
    return{
        type: actions.ADD_TO_COLLECTION,
    }
}

export const addToCollectionSuccess = (payload)=>{
    return{
        type: actions.ADD_TO_COLLECTION_SUCCESS,
        payload
    }
}

export const addToCollectionFailure = (payload)=>{
    return{
        type: actions.ADD_TO_COLLECTION_FAILURE,
        payload
    }
}

// FETCH COLLECTION

export const fetchCollection = (payload)=>{
    return{
        type: actions.FETCH_COLLLECTION_REQUEST,
        payload
    }
}
export const fetchCollectionSuccess = (payload)=>{
    return{
        type: actions.FETCH_COLLLECTION_SUCCESS,
        payload
    }
}
export const fetchCollectionFailure = (payload)=>{
    return{
        type: actions.FETCH_COLLLECTION_FAILURE,
        payload
    }
}

// GET COLLECTION 
export const getCollectionRequest = ()=>{
    return{
        type: actions.GET_COLLECTION_REQUEST,
    }
}
export const getCollectionSuccess = (payload)=>{
    return{
        type: actions.GET_COLLECTION_SUCCESS,
        payload
    }
}
export const getCollectionFailure = (payload)=>{
    return{
        type: actions.GET_COLLECTION_FAILURE,
        payload
    }
}

// DELETE COLLECTION
export const deleteCollection = (payload)=>{
    return{
        type: actions.DELETE_COLLECTION,
        payload
    }
}

















// Todo
// export const deleteCollection = (payload)=>{
//     return{
//         type: actions.DELETE_COLLECTION,
//         payload
//     }
// }