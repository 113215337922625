import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'

import styled from 'styled-components';
import { connect } from 'react-redux'

import SearchForm from '../widget/search-form/search-form';
import randomBackground from '../../utilities/randomBackground';



const StyledLanding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 150px 20px 50px 20px;
    color: #ffffff;
    min-height: 40vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    & .author .author-link{
        font-weight: 800
    }

    & .intro h2 {
        font-size: 30px;
        text-shadow: 2px 2px 1px rgba(0,0,0,.1);
    }
    & intro span {
        font-size: 18px;
        text-shadow: 2px 2px 1px rgba(0,0,0,.1);
    }
    .search-form{
        max-width: 715px
    }

    @media(max-width: 768px){
        padding: 180px 20px 30px 20px;
    }
    

`



const Landing = (props)=>{
    const [imageDetail, setImageDetail] = useState({
        background: '',
        author: '',
        username: ''
    })

    const { 
        state: { 
            photos
         } = {} 
    } = props 
    const { docs } = photos || {}
    

    useEffect(()=>{
        if(docs){ 
            const { image, author, username } = randomBackground(docs) || {}
            setImageDetail({
                background: image,
                author,
                username
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ docs ])

    
    
    
    const landingImage = {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, .3), rgba(0, 0, 0, .5)),url(${imageDetail.background})`,
       
    }
    return(
        <>
            {
                docs ?
                    <StyledLanding style={landingImage} className="landing">
                        <div className='intro'>
                            <span>You love beautify photos?</span>
                            <h2>Explore collections of stock photos by people</h2>
                        </div>
                        <div className="full-width search-form">   
                            <SearchForm 
                                {...props} 
                                withIcon 
                                placeholder='What are you looking for?' 
                            />
                            <p className='grid align-c centered author'>
                                <NavLink 
                                className="author-link"
                                to={`@${imageDetail.username}`}>{imageDetail.author}</NavLink>
                            </p>
                        </div>
                    </StyledLanding>
                    : null
            }
        </>
    )
}


const mapStateToProps = state =>{
    return{
        state: state.photo
    }
}

export default connect(mapStateToProps, null)(Landing)