import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import MessageAlert from '../widget/messages-alert/message';
import { _login, _getUser } from '../../container/redux/services/user.service';
import { AuthWrapper, StyledAuthForm } from './auth-style'
import Modal from '../widget/modals/modal'
import sendMessageWithClear from '../../utilities/sendMessageAlert';
import randID from '../../utilities/randID';


export const desktopBackground = "url(https://images.pexels.com/photos/4045428/pexels-photo-4045428.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)"
export const mobileBackground =  "linear-gradient(to right, rgba(0, 0, 80, .3), rgba(100, 0, 0, .5)),url(https://images.pexels.com/photos/4045428/pexels-photo-4045428.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)"

export const AuthBackgroundstyles = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    background: window.outerWidth > 600 ? desktopBackground : mobileBackground
}



const LoginForm = ({eventHandler, state, loginExtra })=>{
    const { email, password } = state || {}
    const { error, loading } = loginExtra


    return(
        <StyledAuthForm className='login'>

            <form onSubmit={eventHandler}>
                <div className='centered-text form-head'>
                    <h2 className='auth-title'>Continue to your Account</h2>
                </div>
                <div className='no-padding divider'></div>
                <div className='body'>
                    {    error &&
                        error.map( e =>{
                            const { msg } = e
                            return (
                                <MessageAlert 
                                    icon='info' 
                                    type='error' 
                                    message={msg} 
                                    key={randID()}
                                />
                            )
                        })
                    }
                    <div className='field'>
                        <input 
                            onChange={ eventHandler } 
                            value={ email } 
                            type='email' name='email' 
                            placeholder='Email address' 
                            className='form-fix' 
                            required
                        />
                    </div>
                    <div className='field'>
                        <input 
                            onChange={ eventHandler } 
                            value={ password }
                            autoComplete='off' 
                            type='password' 
                            name='password' 
                            placeholder='Password' 
                            className='form-fix' 
                            required
                        />
                    </div>
                    <button 
                        type="submit" 
                        className='blue auth-btn full-width'
                        disabled={ loading ? 'disabled' : null }>Login</button>
               </div>
                <div className='no-padding divider mt-30'></div>
                <div className='centered-text form-foot'>
                    <p>Haven't joined yet?</p>
                    <NavLink className='blue-t' to='/join'>Register</NavLink> &nbsp;|&nbsp;
                    <NavLink className='blue-t' to='/forgot-password'>Forgot password</NavLink>
                </div>
            </form>
        </StyledAuthForm>
    )
}

const Login = (props)=>{
    
    const { 
        dispatch,
        history,
        location,
        state: {
            error, 
            loading,
            loggedIn,
        } = {}
    } = props
    const { state } = location 
    const referer = state ? state.pathname : ''
    const route = !referer ? '/' : referer
   

    useEffect(()=>{
        if( loggedIn ){
            sendMessageWithClear( dispatch, 'Login successful!')
            setTimeout( ()=>{
                history.push(route)
            }, 100)
            dispatch(_getUser())
        } 
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ loggedIn ])

    const checkForEmail = JSON.parse(localStorage.getItem('regUser'))
    useEffect(()=>{
        checkForEmail && localStorage.removeItem('regUser') 
    }, [])
    const [ loginDetail, setLoginDetail ] = useState({
        email: checkForEmail || '',
        password: '',
    })    

    const eventHandler = (e)=>{
        e.preventDefault()
        const { value, name } = e.target
        setLoginDetail({
            ...loginDetail,
            [name] : value
        })
        if(e.type === 'submit'){
            dispatch(_login( loginDetail ))
        }

    }
   
    return(
        <>
            <AuthWrapper style={AuthBackgroundstyles} className="login-wrapper">
                <div className="grid align-c padded-20 demo">
                    <h1>Best place to find awesome collections of stock photos from around Africa</h1>
                    <p>Join over <b>20,000</b> creative African photographer and share memories! </p>
                </div>
                <LoginForm  
                    eventHandler={eventHandler} 
                    state={loginDetail} 
                    loginExtra={{error}}
                />
            </AuthWrapper>
            {
                loading ?
                <Modal title='Working...'>
                    <p className='centered-text'>
                        Logging you in, please wait...
                    </p>
                </Modal>
                : null
            }
        </>
    )
}



const mapStateToProps = state =>{
    return{
        state: state.login
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        dispatch: (action)=>{
            dispatch(action)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)