import React from 'react';
import styled from 'styled-components';
import { ReactComponent as HomeIcon } from '../../../assets/home.svg'

const StyledLogo = styled.div`
    & h2{
        display: flex;
        flex-wrap: no-wrap;
        height: 40px;
        width: 43px ;
        background: ${ props => props.black ? '#aaa' : 'var(--primary-color)' } ;
        text-align: center;
        box-sizing: border-box;
        padding: 3px 0px 0px 2px; 
        color: ${ props => props.black ? '#eee' : 'white' };
        border-radius: 3px;
        fill: #fff;
        transition: .3s ease-out;
        stroke: #fff;
        overflow: hidden
    }
    & h2 > * {
        transition: .3s ease-out;
    }

    & h2 svg.mobile-return{
        transform: translateX(-40px);
        left: -3px;
        top: 3px;
        position: relative;
    }
    & h2 .logo-text{
        transform: translateX(-10px);
        padding-top: 2px;
    }
    & h2.goBack .logo-text{
        transform: translateX(40px);
    }
    & h2.goBack svg.mobile-return{
        transform: translateX(10px);
    }
    & span {
        color: ${ props => props.black ? '#555' :  props.spanOnly ? 'var(--secondary-color)' : 'white' };
    }
    
` 


export const Logo = (props)=>{
    const { compact, loading, goBack } = props
    

    return(
        <>
            {
                compact ?
                <StyledLogo {...props} className="full-width padded-10 grid centered align-c logo">
                   {
                       <h2 className={`no-margin ${ !goBack && 'goBack'}`}>
                           {
                                <>
                                    <HomeIcon 
                                        onClick={
                                            ()=>{ props.history.push('/') }
                                        } 
                                        className='mobile-return'/>
                                    <span className='logo-text'> P </span>
                                </>
                           }
                        </h2>
                   }
                </StyledLogo>
                :
                <StyledLogo {...props} className="full-width padded-10 grid centered align-c logo">
                    <h2 className="no-margin">P</h2>
                    {
                        !loading &&
                        <span>ixare</span>
                    }
                </StyledLogo>
            }
        </>
    )
}


export default Logo