import React from 'react';
import styled from 'styled-components';
import Logo from '../logo/logo';
import screenSize from '../../../utilities/checkScreenSize';
import { ReactComponent as SignalSvg } from '../../../assets/signal.svg'


const StyledLoader = styled.div`
    height: fit-content !important;
    background: transparent !important;
    border: none !important;
    position: relative;
    padding: 20px;
    z-index: 999;
    background: #fff;
    & svg.svg {
        transform: scale(2.5);
        top: -40px;
        position: relative;
        fill: #555;
        margin: 0px auto !important;
    }
    & p{
        font-size: 16px;
        color: #aaa
    }
`
const Loader = ({message})=>{
  
    const { innerHeight } = screenSize()
    const style={
        top: ( innerHeight / 2 - 100 ) + 'px'
    }

    return(
        <StyledLoader style={style} className="grid loader centered">
            <div className="grid col">
                <SignalSvg />
                <p className="no-margin">{message}</p>
            </div>
        </StyledLoader>
    )
}


export default Loader