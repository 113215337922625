/* eslint-disable no-unused-vars */
import React from 'react'
import { NavLink } from 'react-router-dom'

import styled from 'styled-components';
import AuthorPill from '../../widget/photo/author';
import PrimaryButton from '../../widget/buttons/buttons';
import PhotoLocation from '../../widget/photo-action/location';
import randID from '../../../utilities/randID';


const StyledPhotoDetail = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0px 10px ;
    box-sizing: border-box;
    box-shadow: 1px 2px 3px rgba(0,0,0,.05);
    border-top: 1px solid #eee !important;
    border-radius: 3px;
    border: none;

    @media(max-width: 768px){
        flex-direction: column;
        padding: 0px !important
    }

    
`
const StyledDetail = styled.ul`
   margin-top: 0px;
   margin-bottom: 0px;
   padding: 20px;
   flex: 1;
   &.stat{
        min-width: 150px;
   }
   & h4{
        color: #555;
        margin-bottom: 8px;
    }
   & i {
        top: 0px;
        font-size: 16px;
        color: inherit;
        margin: 0px !important;
        padding-right: 10px !important;
    }
   & p {
       color: #555;
       font-size: 18px;
       margin: 8px 0px;
   }
   & p.labels .label{
       padding: 3px 10px;
       border-radius: 3px;
       background: #fafafa;
       margin-left: 0px;
   }
   @media(min-width: 768px){
        border-right: 1px solid #f2f2f2;
        width: fit-content;
        padding-right: 20px;
        & p {
            max-width: 200px;
        }
        &:last-child{
            border: none
        }
   }
   @media(max-width: 768px){
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 20px;
        &:last-child{
            border: none
        }
    }
    @media screen and (max-width: 600px){
        &.stat .stat-count{
            display: flex;
            justify-content: space-around ;
            font-size: 20px;
            font-weight: 600
       }
       &.stat .stat-count p {
            display: flex;
            flex-direction: column; 
            align-items: center
       }
       &.stat .stat-count p i {
           font-size: 45px;
           padding-bottom: 10px;
       }
       
    }
`
const StyledMetadata = styled.ul`
    padding: 0px;
    display: grid;
    grid-template-columns: auto auto ;

    & li{
        padding: 8px 15px;
        box-sizing: border-box;
        &:nth-child(odd){
            border-right: 1px solid #f2f2f2
        }
        &:nth-child(3){
            border-top: 1px solid #f2f2f2
        }
        &:nth-child(4){
            border-top: 1px solid #f2f2f2
        }
    }
    @media(min-width: 768px){
        &:first-child{
            padding-left: 0px;
        }
        grid-template-columns: auto auto;
        & li {
            border: none !important
        }
        
    }
`

const Metadata = ({meta})=>{
    const { focus, height, width, exposure, camera, } = meta || {}

    return(
        <StyledMetadata>
            <li>
                <p>Focal Length</p>
                <small>
                    <b>12m</b>
                </small>
            </li>
            <li>
                <p>Exposure</p>
                <small>
                    <b>3.4</b>
                </small>
            </li>
            <li>
                <p>Camera</p>
                <small>
                    <b>Lenovo K5 PRO</b>
                </small>
            </li>
            <li>
                <p>Dimension</p>
                <small>
                    <b>= {height} &times; {width}</b>
                </small>
            </li>
        </StyledMetadata>
    )
}


const PhotoDetail = (props)=>{
    const { author, ownPost, photo } = props || {}
    const { followers, location:authorLocation, name:photographer, username, avatarUrl } = author || {}
    const { caption:title, category, createdAt, likes, location:photoLocation, tags, views, _id:photoId } = photo || {}
    const date = new Date(createdAt).toDateString()
    const time = new Date( createdAt ).toLocaleTimeString()
   

    return(
        <StyledPhotoDetail>
            <StyledDetail>
                <h4>Photographer</h4>
                <AuthorPill
                    photographer={ photographer } 
                    followers={ followers }
                    image={ avatarUrl }
                    username={ username }
                    inverted={true}
                    plain={true}
                    follow = {`More photos by @${ username }`}
                    padding="10px 0px"
                    typeMore
                />
                <PrimaryButton
                    download
                    target='_blank'
                    aslinkext={ avatarUrl }
                    margin="8px 0px"
                    background="var(--secondary-color)"
                >
                    Download Photo
                </PrimaryButton>
            </StyledDetail>
            <StyledDetail>
                <h4>Location and Date Taken</h4>
                <PhotoLocation
                    fontSize='14px'
                    location={ photoLocation }
                    inverted={'#555'} 
                />
                <p>
                    <i className='material-icons'>date_range</i> {date}
                </p>
                <p>
                    <i className='material-icons'>timer</i> {time}
                </p>
            </StyledDetail>
            <StyledDetail>
                <h4>Tags and summary</h4>
                <p>
                    { title }
                </p>
                <p className='grid labels'>
                    {
                        true && 
                        tags.map( t=>{
                            return(
                                <NavLink
                                    key={randID()}
                                    to={`/images/${t}`}
                                    className='label'
                                >{t}
                                </NavLink>
                            )
                        })
                    }
                </p>
            </StyledDetail>
            <StyledDetail className=' stat'>
                <h4> Statistics</h4>
                <div className=' stat-count'>
                    <p>  
                        <i className='material-icons'>remove_red_eye</i> { views } { views > 1 ? 'views' : 'view'}  
                    </p>
                    <p>
                        <i className='red-t material-icons'>favorite</i> { likes } Likes
                    </p>
                </div>
            </StyledDetail>

            {/* TODO */}
            {/* <StyledDetail larger>
                <h4>Photo Metadata </h4>
                <Metadata meta={ { height : 700,width : 200 }} />
            </StyledDetail> */}
            
        </StyledPhotoDetail>
    )
}

export default PhotoDetail