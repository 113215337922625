/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components';

import randID from '../../utilities/randID'
import EmptyContent from '../widget/empty-content/empty-content';
import NewAlbumButton from '../widget/createNewAlbumButton/createNewAlbumButton';
import { _fetchCollection } from '../../container/redux/services/collection.service';
import AlbumList  from '../widget/album/album' 
import TabIntro from './tab-intro';
import InfiniteLoader from '../widget/loader/infiniteLoader';




const StyledAlbumWrapper = styled.div`
    & .album{
        width: 33.3%;
        max-width: 350px;
    }
    
    @media screen and (max-width: 768px){
        & .album{
            width: 50%
        }
    }
    
    @media(max-width: 650px){
        & .albums{
            align-items: center;
            flex-direction: column
        }
        & .albums .album{
            width: 100%;
            max-width: 100%;
        }
    }
    
`

const Album = (props)=>{
    const { 
        currentUser: { 
            username
        } = {},
        isAuth, 
        state: { collections, loading,account } = {} ,
        dispatch    
    } = props
    const { docs: collectionList, totalDocs } = collections || {}



    useEffect(()=>{
        _fetchCollection(username, dispatch)
    }, [ account,username ])
    
    
    return(
        <StyledAlbumWrapper className='photo-album'>
            {
                collectionList && totalDocs && !loading ? 
                <>
                    <TabIntro
                    title={`Collection ${isAuth ? 'you' : "" } created (${totalDocs || "..."})`}
                    description='You can create more collections by collecting a photo' />
                    <div className={`gap-10 grid albums ${ totalDocs > 2 ? 'centered' : '' }`}>
                        {
                            collectionList.map( album =>{
                                return(
                                    <AlbumList
                                    userName={username} 
                                    album={album} 
                                    key={randID()}
                                    isAuth={isAuth}
                                    dispatch={dispatch}/>
                                )
                            })
                        }
                    </div>
                </>
                :
                !loading && 
                <div className='grid col align-c centered empty'>
                    <EmptyContent title={`Nothing to show`}>
                        <svg 
                            className='resize'
                            xmlns="http://www.w3.org/2000/svg" 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24">
                            <path d="M4 17.162l-2 .838v-12.972l12-5.028v2.507l-10 4.19v10.465zm16-8.156v8.635l-8 3.352v-8.635l8-3.352zm2-3.006l-12 5.028v12.972l12-5.028v-12.972zm-14 3.697l10-4.19v-2.507l-12 5.028v12.972l2-.838v-10.465z"/>
                        </svg>
                    </EmptyContent>
                    {
                        isAuth ? <NewAlbumButton compact /> : null
                    }
                </div>
            }
            {
                loading ? 
                <InfiniteLoader /> : null
            }
        </StyledAlbumWrapper>
    )
}










const mapStateToProps = (state) => ({
    state: state.collection
})
const mapDispatchToProps = (dispatch)=>{
    return{
        dispatch: action =>{dispatch(action)}
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Album)