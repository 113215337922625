
export const CREATE_COLLECTION = "CREATE_COLLECTION"

export const CREATE_COLLECTION_FAILURE = "CREATE_COLLECTION_FAILURE"

export const CREATE_COLLECTION_SUCCESS = "CREATE_COLLECTION_SUCCESS"


export const ADD_TO_COLLECTION = "ADD_TO_COLLECTION"

export const ADD_TO_COLLECTION_FAILURE = "ADD_TO_COLLECTION_FAILURE"

export const ADD_TO_COLLECTION_SUCCESS = "ADD_TO_COLLECTION_SUCCESS"


export const FETCH_COLLLECTION_REQUEST  = "FETCH_COLLECTION_REQUEST"

export const FETCH_COLLLECTION_SUCCESS  = "FETCH_COLLECTION_SUCCESS"

export const FETCH_COLLLECTION_FAILURE  = "FETCH_COLLECTION_FAILURE"


export const DELETE_COLLECTION = "DELETE_COLLECTION"


export const GET_COLLECTION_REQUEST = "GET_COLLECTION_REQUEST"

export const GET_COLLECTION_SUCCESS = "GET_COLLECTION_SUCCESS"

export const GET_COLLECTION_FAILURE = "GET_COLLECTION_FAILURE"