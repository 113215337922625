import { createStore, applyMiddleware, combineReducers } from "redux"
import Logger  from "redux-logger"
import  thunk  from 'redux-thunk'

import photoReducer from "../reducers/photo.reducer";
import SearchReducer from "../reducers/search.reducer";
import UploadReducer from "../reducers/upload.reducer";
import SystemReducer from "../reducers/system.reducer";
import collectionsReducer from "../reducers/collections.reducer";
import AlertReducer from "../reducers/alert.reducer";
import likesReducer from "../reducers/likes.reducer";
import ViewReducer from "../reducers/photo-view-reducer";
import LoginReducer from "../reducers/login.reducer";
import RegisterReducer from "../reducers/register.reducer";
import AccountReducer from "../reducers/account.reducer";
import UserReducer from "../reducers/user.reducer";
import Engagement from "../reducers/engagement.reducer";
import sendMessageWithClear from "../../../utilities/sendMessageAlert";


// Custom middleware to handle logging
const toaster = ({ dispatch }) => next => action => {
    const { payload } = action || {};
    if( 
        typeof(payload) === 'object' 
        && ( payload.hasOwnProperty('msg' ) 
        && payload.msg )){
            sendMessageWithClear(dispatch, payload.msg);
        }
    next(action);
}

const rootReducer = combineReducers({
    // ACCOUNT RELATED 
    login: LoginReducer,
    register: RegisterReducer,
    account: AccountReducer,
    user: UserReducer,

    // APP CORE
    photo: photoReducer,
    search: SearchReducer,
    upload: UploadReducer,
    engagement: Engagement,

    // SYSTEMS AND NOTIFICATIONS
    system: SystemReducer,
    alerts: AlertReducer,

    // ENGAGEMENTS
    collection: collectionsReducer,
    like: likesReducer,
    view: ViewReducer,
})


const { NODE_ENV } = process.env
const devMode = NODE_ENV === 'development' ? true : false
const devStore = createStore( rootReducer , applyMiddleware(
    thunk, toaster, Logger
))
const prodStore = createStore( rootReducer , applyMiddleware( thunk, toaster ))
const store = devMode ? devStore : prodStore



export default store