import * as actions from './user-action'


// GET USER
export const getUserRequest = ()=>{
    return{
        type: actions.GET_USER_REQUEST
    }
}

export const getUserSuccess = (payload)=>{
    return{
        type: actions.GET_USER_SUCCESS,
        payload
    }
}

export const getUserFailure = (payload)=>{
    return{
        type: actions.GET_USER_FAILURE,
        payload
    }
}

// GET OTHER USERS


export const userProfileRequest = ()=>{
    return{
        type: actions.USER_PROFILE_REQUEST
    }
}


export const userProfileSuccess = (payload)=>{
    return{
        type: actions.USER_PROFILE_SUCCESS,
        payload
    }
}


export const userProfileFailure = (payload)=>{
    return{
        type: actions.USER_PROFILE_FAILURE,
        payload
    }
}

// UPDATE USER FOLLOW LIST

export const updateFollowList = (payload)=>{
    return{
        type: actions.UPDATE_FOLLOW_LIST,
        payload
    }
}

export const followUserUpdate = (payload)=>{
    return{
        type: actions.FOLLOW_USER_UPDATE,
        payload
    }
}