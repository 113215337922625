import React from 'react';
import { NavLink, Link } from 'react-router-dom'

import { ReactComponent as UploadSvg } from '../../../../assets/upload.svg'
import styled from 'styled-components';


const StyledExtraNav = styled.li`
    box-sizing: border-box;
    padding: 0px;
    width: fit-content;
    & img{
        height: 28px;
        width: 28px;
        min-width: 28px;
        border-radius: 100%;
        border:1.5px solid #fff;
        padding: 4px;
    }
    &  svg {
        cursor: pointer;
        fill: #777;
    }
    & svg:hover{
        fill: var(--secondary-color);
    }
    & .upload {
        margin-right: 5px;
    }
    @media(min-width: 769px){
        & > a {
            margin: 0px 20px 
        }
    }
    @media(max-width: 768px){
        & > a:nth-child(2) {
             display: none
        }
    }
`

const CompactNav = ({adjustIcons, user})=>{
   

    

    return(
        <>
            <StyledExtraNav className='no-wrap grid align-c centered extras'>
                <NavLink title="Upload" to="/upload">
                    <UploadSvg style={adjustIcons}/>
                </NavLink>
                {
                    user &&
                    <Link title="Profile" to={`/@${user.username}`}>
                        <img src={user.avatar} className={adjustIcons} alt='profile' />
                    </Link>
                }
            </StyledExtraNav>
        </>
    )
} 




export default CompactNav