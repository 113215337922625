// GET USER
export const GET_USER_REQUEST = 'GET_USER_REQUEST'

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'

export const GET_USER_FAILURE = 'GET_USER_FAILURE'

// GET USER
export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'

export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'

export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE'

export const FOLLOW_USER_UPDATE = 'FOLLOW_USER_UPDATE'

// UPDATING USER FOLLOW

export const UPDATE_FOLLOW_LIST = 'UPDATE_FOLLOW_LIST'
