import * as actions from './photo-view-action'


// SEARCHING FOR PHOTO MATCH
export const findMatchBegin = ()=>{
    return{
        type: actions.FIND_MATCH_BEGIN,
    }
}

export const findMatchSuccess = (payload)=>{
    return{
        type: actions.FIND_MATCH_SUCCESS,
        payload
    }
}

export const findMatchFailure = (payload)=>{
    return{
        type: actions.FIND_MATCH_FAILURE,
        payload
    }
}


