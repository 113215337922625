import * as uploadActions from '../actions/upload-action/upload-action-creator'
import * as photoActions from '../actions/photo-action/photo-action-creator'
import * as photoViewActions from '../actions/photo-view-action/photo-view-action-creator'
import * as likeActions from '../actions/likes-actions/likes-action-creator'

import axios from 'axios'
import authHeader from '../../../utilities/authHeader';
import AxiosCall from '.';


export const _uploadPhoto = (payload) =>{
    

    return (dispatch)=>{
        dispatch(uploadActions.uploadRequest({msg: 'Uploading...'}))
        
        const API_ENDPOINT = AxiosCall(`post/photo`)
        const MY_HEADERS = {
            Authorization: `Bearer ${authHeader()}`
        }
        function queuePhotos(files){
            const formData = new FormData()

            for( let file in files ){
                const index = files[file]

                const { description, caption, category, tags, location, raw } = index

                formData.append('photo', raw, caption )
                formData.append('category', category)
                formData.append('tags', tags)
                formData.append('description', description)
                formData.append('location', location)
                formData.append('caption', caption)
            }
            return formData
        };

    

        const REQUEST_OPTIONS = {
            method: 'POST',
            data: queuePhotos(payload),
            headers: MY_HEADERS,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data } = {}  } = response
            dispatch(uploadActions.uploadSuccess(data))
            dispatch(photoActions.updatePhotoList( data.photo ))
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch(uploadActions.uploadFailure({msg}))
        })
        
    }
}

export const _fetchUserPhoto = ( username, dispatch )=>{

        function fetchPhoto(){
            dispatch(photoActions.fetchUserPhoto(username))

            const API_ENDPOINT = AxiosCall(`user/photos?username=${ username }`)
            const MY_HEADERS = {
                Authorization: `Bearer ${authHeader()}`
            }

            const REQUEST_OPTIONS = {
                method: 'GET',
                headers: MY_HEADERS,
                // redirect: 'follow'
            }
            axios( API_ENDPOINT , REQUEST_OPTIONS)
            .then( response => {
                const { data: { data } = {}  } = response
                dispatch(photoActions.fetchUserPhotoSuccess(data))
            })
            .catch( error => {
                const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
                dispatch(photoActions.fetchUserPhotoFailure({ msg }))
            })
        }
        return fetchPhoto()
}

// fetch all photos
export const _fetchPhotos = ( dispatch )=>{

    function fetchPhoto(){
        dispatch(photoActions.fetchPhotoBegin())

        const API_ENDPOINT = AxiosCall(`post/photo/all/allPhotos`)
        const REQUEST_OPTIONS = {
            method: 'GET',
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data } = {}  } = response
            dispatch(photoActions.fetchPhotoSuccess(data))
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch(photoActions.fetchPhotoFailure({msg}))
        })
    }
    return fetchPhoto()
}



// find photo match
export const _findPhotoMatch = ( dispatch, photoId )=>{
   
    function findMatch(){
        dispatch(photoViewActions.findMatchBegin())

        const API_ENDPOINT = AxiosCall(`post/photo/${ photoId }`) 
        const REQUEST_OPTIONS = {
            method: 'GET',
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data } = response
            dispatch(photoViewActions.findMatchSuccess(data))
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch(photoViewActions.findMatchFailure({ msg }))
        })
    }

    return findMatch()
}


// Like/unlike photo
export const _likePhoto = ( photoId, action )=>{
   
    return ( dispatch )=>{
       
        const API_ENDPOINT = AxiosCall(`post/photo/${ action === 'like' ? 'like' : 'unlike' }/${ photoId }`) 
        const MY_HEADERS = {
            Authorization: `Bearer ${authHeader()}`
        }

        const REQUEST_OPTIONS = {
            method: 'PATCH',
            redirect: 'follow',
            headers: MY_HEADERS
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data } = response
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch(likeActions.likeFailure({ msg }))
        })
    }
}

// Like photo
export const _fetchLikedPhotos = ( dispatch, username )=>{

    function beginFetch( username ){
        const API_ENDPOINT = AxiosCall(`user/likes?username=${ username }`) 
        const REQUEST_OPTIONS = {
            method: 'GET',
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data } = response
            dispatch(likeActions.fetchPhotoSuccess(data.data))
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch(likeActions.likeFailure({ msg }))
        })
    }
    return beginFetch( username )
}