import React from 'react'
import styled from 'styled-components';

const StyledProgressBar = styled.div`
    height: 5px;
    width: 100%;
    background: rgba(0, 200, 0, .3);
    & .loader{
        height: 5px;
        background: var(--light-green);
		position: relative;
		box-sizing: border-box;
		transition: width .5s ease-in;
    }
    & .loader:before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: linear-gradient(to  right, rgba(0, 200, 0, .3), rgba(0, 100, 0, .8));
		animation: purge 6s infinite 
    }
    
    @keyframes purge{
		0%{
			opacity: 0;
			width: 0%;

		}
		50%{
			opacity: .5
		}
		100%{
			opacity: 0;
			width: 100%;
		}
	}
`


export const ProgressBar = ({progress, uploadError})=>{

    // Upload State indicator
    const progresStyle= {
        container: {
            background: `${uploadError ? 'rgba(200, 0, 0, .3)' : ''}`
        },
        loader: {
            width: `${progress}%`,
            background: `${uploadError ? 'var(--red)' : ''}`
        }
    }


    return(
        <StyledProgressBar style={progresStyle.container}>
            <div style={progresStyle.loader} className="loader"></div>
        </StyledProgressBar>
    )
}


export default ProgressBar