import randID from "./randID";
import { logMessage, clearMessage } from "../container/redux/actions/alert-actions/alert-action-creator";


const sendMessageWithClear = (dispatch, message)=>{
    let messageID = randID()
    
    dispatch(logMessage({
        message, 
        id: messageID
    }))
    
    // Clear message
    setTimeout(()=>{
        dispatch(clearMessage(messageID))
    }, 3000)
}

export default sendMessageWithClear
