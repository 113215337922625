import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import styled from 'styled-components';
import PrimaryButton from '../widget/buttons/buttons';
import Modal from '../widget/modals/modal';
import getAttribute from '../../utilities/getAttribute';
import Uploader from '../uploads/upload-widgets/uploader';
import generatePhotoPreview from '../../utilities/generatePhotoPreview';
import { _changeAvatar } from '../../container/redux/services/user.service';
import InfiniteLoader from '../widget/loader/infiniteLoader';
import sendMessageWithClear from '../../utilities/sendMessageAlert';
import { _followUser, _unFollowUser } from '../../container/redux/services/engagement.service';
import authHeader from '../../utilities/authHeader';




const StyledProfileHead = styled.div`
    
    & .img-avatar{
        height: 120px;
        width: 120px;
        border-radius: 100%;
        background: #eee;
        box-sizing: border-box;
        margin-right: 25px;
        top: 20px;
        position: relative
    }
    & .img-avatar img{
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 100%;
    }
    & .img-avatar .edit-avatar{
        position: absolute;
        top: 70%;
        right: 0px;
        background: #fff;
        border-radius: 100%;
        padding: 3px;
        font-size: 16px;
        color: var(--primary-color);
        cursor: pointer;
    }

    & .about{
        position: relative;
        margin-bottom: -8px;
    }
    & .img-avatar img{
        object-fit: cover
    }
    & .about .author > * {
        letter-spacing: 1.2px;
        margin-top: 5px;
    }
    & .about .author .id-origin{
        text-transform: capitalize;
    }
    & .about .follow-btn{
        padding: 8px 30px;
        color: #fff;
        font-weight: 600;
        background: var(--light-green)
    }
    & .about .followers .follow-img{
        height: 30px;
        width: 30px;
        overflow: hidden;
        background: #eee;
        margin-right: -15px;
        border-radius: 100%;
        box-sizing: border-box !important
    }
    & .about .followers .follow-img {
        object-fit: contain
    }
    & .about .followers .follow-img:last-child{
        font-size: 7px;
        line-height: 1;
        text-align: center;
        padding: 6px 0px 0px 2px;
        font-weight: 600;
        border: .1px solid #aaa;    
    }
    & .stats .follow{
        text-align: center
    }
    & .stats .follow .stat{
        display: flex;
        flex-direction: column;
        text-align-center
    }
    & .stats .follow .stat .count{
        font-weight: 800;
        font-size: 16px;
    }

    @media screen and (min-width: 900px){
        & .about .author .name {
            font-size: 3em;
            padding-bottom: 5px;
        }
        & .about .author .id-origin{
            font-size: 18px;
        }
    }
    @media(max-width: 768px){
        & .about .author > * {
            margin-top: 0px;
        }

        & .img-avatar{
            height: 110px;
            width: 110px;
            top: 20px;
        }
        & .about .author .name {
            font-size: 2em;
            padding-bottom: 3px;
        }
        & .about .author .id-origin{
            padding: 5px;
        }
        & .about .follow-btn{
            padding: 0px 10px !important;
            font-size: 14px;
            height: 30px
        }
    }

    @media screen and (max-width: 600px){
        & .about .author .name {
            font-size: 1.5em;
        }
    }

    @media screen and (max-width: 416px){ 
        & .img-avatar{
            height: 80px;
            width: 80px;
            top: 0px;
            margin-right: 15px;
        }
        

    }

` 
const StyledStatistics = styled.div`
    & button{
        min-width: 100px;
    }
    @media(max-width: 416px){
        font-size: 12px;
        min-width: 100px;
    }
`

const Statistics = ({data, loading})=>{
    const { 
        handleFollow, 
        isAuth, 
        followers, 
        following,
        followingUser  
    } = data

    const parseCount = (value)=>{
        return value > 1000 ? `${ value/1000 }K` : value
    }

    return(
        <StyledStatistics className='align-c grid stats'>
            <ul className='no-padding grid follow'>
                <li className='stat'>
                    <span className='count'> { parseCount(followers) }</span>
                    <small>Followers</small>
                </li>
                {
                    isAuth && 
                    <li className='stat'>
                        <span className='count'> { parseCount(following) }</span>
                        <small>Following</small>
                    </li>
                }
            </ul>
            {
                !isAuth &&
                <PrimaryButton 
                    padding=' 10px 20px'
                    borderRadius="50px"
                    data-action={`${ followingUser ? 'unfollow' : 'follow'}`}
                    onClick={ handleFollow }
                    bold="600"
                    shadow='0px'
                >
                    { loading ? '. . .' : followingUser ? 'Unfollow' : 'Follow'} 
                </PrimaryButton>
            }
        </StyledStatistics>
    )
}


const ProfileHead = (props)=>{
    
    const { 
        history, 
        user, 
        dispatch, 
        account: { 
            message, 
            loading 
        } = {}, 
        engagement: { 
            loading: followLoading 
        } 
    } = props 
    
    // Guest user
    const { 
        isAuth, 
        currentUser, 
        authenticatedUser, 
    } =  user || {}

    const { 
        name, 
        username, 
        avatar, 
        date, 
        followers, 
        following, 
        _id, 
        followingUser, 
        followingYou  
    } = currentUser || {}

    const joined = new Date(date).toDateString()

    // UPLOAD PROFILE IMAGE
    const [ userAvatar, setUserAvatar ] = useState(false)
    const [ file, setFile ] = useState(null)
    const handleUpload = (e)=>{
        const name = getAttribute(e.target, 'action')
        if(name === 'avatar'){
            setUserAvatar(true)
        }else if( name === 'uploader'){
            const { preview, type } = generatePhotoPreview(e.target.files) || {}
            const uploadType = type ? type.split('/')[0] : null
            if ( uploadType ==='image'){
                setFile({
                    raw: e.target.files[0],
                    preview
                })
            }else{
                sendMessageWithClear(dispatch, 'File format not supported!')
            }
        }else if( name === 'dispatchUpload'){
            if(file){
                dispatch(_changeAvatar(file.raw))
            }
        }
    }
    const resetState = ()=>{
        setUserAvatar(false)
        setFile('')
    }


  
    // USER FOLLOW
    const handleFollow = (e)=>{
        const action = getAttribute(e.target, 'action')

        if( authenticatedUser && authHeader() ){
            if( action === 'follow' && !followingUser ){
                dispatch(_followUser(_id))
            }else if( action === 'unfollow' && followingUser ){
                dispatch(_unFollowUser(_id))
            }
        }else{
            const { location: { pathname } } = history ||  {}
            sendMessageWithClear(dispatch, 'You must be logged in to follow a user!')
            history.push('/login', { pathname })
        }
    }

        
    


    return(
        <>
            {
                user &&
                <StyledProfileHead className="grid align-c profile-head">
                    <div className="img-avatar">
                        <img src={avatar} alt={`${username}'avatar`} />
                        {
                            isAuth ?
                            <i 
                            onClick={handleUpload}
                            data-action='avatar'
                            title="Edit profile image" 
                            className='edit-avatar material-icons'>edit</i>
                            : null
                        }
                    </div>
                    <div className="grid align-e about">
                        <div className='author'>
                            <h2 className='name'>{ name }</h2>
                            <p className='id-origin margin-bottom-5 no-margin'>@{ username } | Lagos, Nigeria</p>
                            { 
                                isAuth ?
                                <small className=' join-data grey-t'> Joined <span className=''>{ joined }</span></small>
                                : null
                            }
                            <Statistics 
                                data={{ 
                                    followers, 
                                    following, 
                                    isAuth, 
                                    handleFollow, 
                                    followingUser, 
                                    followingYou,}}
                                loading={followLoading} />
                        </div>
                    </div>

                    {
                        userAvatar && 
                        <Modal 
                        adjustHeight
                        resetModalState={resetState}
                        isOpen={ userAvatar} 
                        title='Change profile image'>
                            <Uploader uploading={loading} handleUpload={handleUpload}/>
                            {
                                file ?
                                <img 
                                src={file.preview} 
                                className='profilePreview' 
                                alt='preview' 
                                style={{
                                    maxHeight:'250px',
                                    width: ' 100%',
                                    objectFit: 'contain'
                                }}/>
                                : null
                            }
                            {
                                message ?
                                <p className='centered-text'>{message}</p> 
                                : null
                            }
                            <PrimaryButton
                                onClick={handleUpload}
                                data-action='dispatchUpload'
                                disabled={ !file || loading ? 'disabled' : '' }
                                background='var(--dark-green)'
                                margin=' 8px auto'
                            >Set as profile picture</PrimaryButton>
                        </Modal>
                    }
                </StyledProfileHead>          
            }
        </>
    )
}



const mapStateToProps = state =>{
    return{
        account: state.account,
        engagement: state.engagement,
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        dispatch: (action)=>{
            dispatch(action)
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileHead))