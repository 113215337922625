import * as actions from './likes-actions'


export const likeSuccess = (payload)=>{
    return{
        type: actions.LIKE_SUCCESS,
        payload
    }
}
export const likeFailure = (payload)=>{
    return{
        type: actions.LIKE_FAILURE,
        payload
    }
}


export const unLikeSuccess = (payload)=>{
    return{
        type: actions.UNLIKE_SUCCESS,
        payload
    }
}

export const unLikeFaiulure = (payload)=>{
    return{
        type: actions.UNLIKE_FAILURE,
        payload
    }
}


export const fetchPhotoBegin = ()=>{
    return{
        type: actions.FETCH_PHOTO_BEGIN,
    }
}

export const fetchPhotoSuccess = (payload)=>{
    return{
        type: actions.FETCH_PHOTO_SUCCESS,
        payload
    }
}

export const fetchPhotoFailure = ()=>{
    return{
        type: actions.FETCH_PHOTO_FAILURE,
    }
}

