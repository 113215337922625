import * as actions from "../actions/photo-action/photo-action"


const initialState = {
    photos: null,
    loading: false,
    relatedPhotos: [],
    userPhotos: null,
    currentUser: ''
}



const photoReducer = ( state = initialState, action )=>{
    const { type, payload } = action

    switch(type){
        case actions.FETCH_PHOTO_BEGIN:
            return{
                ...state,
                loading: true,
                category: payload

            }
        case actions.FETCH_PHOTO_SUCCESS:
            return{
                ...state,
                loading: false,
                photos: payload
            }
        case actions.FETCH_PHOTO_FAILURE:
            return{
                ...state,
                loading: false,
                photos: state.photos
            }
        case actions.FETCH_USER_PHOTO_REQUEST:
            return{
                ...state,
                loading: true,
                error: false,
                currentUser: payload
            }
        case actions.FETCH_USER_PHOTO_SUCCESS:
            return{
                ...state,
                loading: false,
                userPhotos: payload
            }
        case actions.FETCH_USER_PHOTO_FAILURE:
            return{
                ...state,
                loading: false,
                userPhotos: null
            }
        case actions.UPDATE_PHOTO_LIST: 
            return{
                ...state,
                userPhotos: [ { payload }, ...state.userPhotos.docs ]
            }            
        
        default: 
            return state
    }

}


export default photoReducer