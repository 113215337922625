import * as actions from '../actions/auth-actions/auth-action'

let user = localStorage.getItem('userToken')

const INITIAL_STATE = user ? {
    loggedIn: false
}
: {}

const LoginReducer = ( state = INITIAL_STATE, action )=>{
    const { type, payload } = action
    switch( type ){
        case actions.LOGIN_REQUEST: 
            return { 
                loading: true
            }
        case actions.LOGIN_SUCCESS: 
            const { data: { token, user } = {} } = payload
            localStorage.setItem('userToken', token )
            
            return{
                user,
                loading: false,
                loggedIn: true,
                error: ''
            }
        case actions.LOGIN_FAILURE: 
            return{
                loading: false,
                loggedIn: false,
                error: payload,
            }
        case actions.LOGOUT_REQUEST: 
            return{
                logOut: {
                    loading: true
                }
            }
        case actions.LOGOUT_SUCCESS: 
            const { data: { msg } = {} } = payload
            localStorage.removeItem('userToken')
            return {
                logOut: {
                    message: msg,
                }
            }
        case actions.LOGOUT_FAILURE: 
            const { 0: { msg: message } = {} } = payload || {}

            return {
                logOut: {
                    error: message ,
                }
            }
        default: 
            return state
    }
}

export default LoginReducer