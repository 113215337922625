import * as accountActions from './acount-action'

// UPLOADING  PROFILE IMAGE
export const avatarChangeRequest = ()=>{
    return{
        type: accountActions.AVATAR_UPLOAD_REQUEST
    }
}

export  const avatarChangeSuccess = (payload)=>{
    return{
        type: accountActions.AVATAR_UPLOAD_SUCCESS,
        payload
    }
}


export  const avatarChangeFailure = (payload)=>{
    return{
        type: accountActions.AVATAR_UPLOAD_FAILURE,
        payload
    }
}

// ACCOUNT DETAILS

// ACCOUNT UPDATE
export const updateAccountRequest = ()=>{
    return{
        type: accountActions.UPDATE_ACCOUNT_REQUEST
    }
}


export const updateAccountSuccess = (payload)=>{
    return{
        type: accountActions.UPDATE_ACCOUNT_SUCCESS,
        payload
    }
}


export const updateAccountFailure = (payload)=>{
    return{
        type: accountActions.UPDATE_ACCOUNT_FAILURE,
        payload
    }
}


// PASSWORD UPDATE 
export const changePasswordRequest = ()=>{
    return{
        type: accountActions.CHANGE_PASSWORD_REQUEST
    }
}


export const changePasswordSuccess = (payload)=>{
    return{
        type: accountActions.CHANGE_PASSWORD_SUCCESS,
        payload
    }
}


export const changePasswordFailure = (payload)=>{
    return{
        type: accountActions.CHANGE_PASSWORD_FAILURE,
        payload
    }
}


// ACCOUNT DELETION

export const deleteAccountRequest = ()=>{
    return{
        type: accountActions.DELETE_ACCOUNT_REQUEST
    }
}


export const deleteAccountSuccess = (payload)=>{
    return{
        type: accountActions.DELETE_ACCOUNT_SUCCESS,
        payload
    }
}


export const deleteAccountFailure = (payload)=>{
    return{
        type: accountActions.DELETE_ACCOUNT_FAILURE,
        payload
    }
}

