import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import styled from 'styled-components'
import Dropdown from '../widget/dropdown/dropdown';
import Photo from '../widget/photo/photo';
import Void from '../widget/void-content/void';
import randID from '../../utilities/randID';
import Loader from '../widget/loader/loader';
import { StyledResponsivePhotoTab } from '../profile/photo-tab';
import InfiniteLoader from '../widget/loader/infiniteLoader';
import { _fetchPhotos } from '../../container/redux/services/photo.service';

const TopBar = styled.div`
    border-bottom: 1px solid #eee;
    color: #555;
    background: #fff;
    fontSize: 1.5em;
    transition: .3s;
    z-index: 9999;
    & span{
        text-transform: capitalize;
        color: #000;
    }

    @media(max-width: 768px){
        justify-content: space-between !important;
        padding: 10px 20px
    }
` 

const StyledGallery = styled.div`
    position: relative;
`


// const Topbar = (props)=>{

//     const { state: { photo : {category} } } = props
//     const categories = [
//         { title: 'Trending', value: 'trending' },
//         { title: 'Nature', value: 'nature' },
//         { title: 'Education', value: 'education' },
//         { title: 'Health', value: 'health' },
//         { title: 'AI', value: 'artificail intelligence' },
//         { title: 'Football', value: 'football' },
//         { title: 'Wild', value: 'wild' },
//         { title: 'Science', value: 'science' },
//         { title: 'Sport', value: 'sport' },
//         { title: 'Adult', value: 'adult' },
//         { title: 'Animations', value: 'animals' },
//         { title: 'Military', value: 'military' },
//         { title: 'Defense', value: 'defence' },
//         { title: 'Religion', value: 'religion' },
//         { title: 'Music', value: 'music' },
//         { title: 'Business', value: 'business' },
        
//     ]



//     return(
//         <TopBar className="padded-10 grid grey-t around align-c topbar">
//             <span>{category} photos</span>
//             <Dropdown {...props} type="list" data={categories} />
//         </TopBar>
//     )
// }


const Gallery = (props)=>{
    const { 
       dispatch,
       state
    } = props
    const { loading, photos: photoList, message } = state || {}
    const { docs } = photoList || {}
    

    useEffect(()=>{
        if(!photoList){
            _fetchPhotos(dispatch)
        }
    }, [ photoList, dispatch])



    return(
        <StyledGallery>
            {
                docs && !loading &&
                <>
                    <StyledResponsivePhotoTab>
                        {
                            docs.map( (photo) =>{
                                return(
                                    <Photo 
                                        payload={photo} 
                                        key={randID()}
                                    />
                                )
                            })
                        }
                    </StyledResponsivePhotoTab>
                </>
            }
            {
                loading && 
                <>
                    <InfiniteLoader />
                    <Loader 
                    adjustposition
                    message='Fetching contents, please wait...' />
                </>
            }
            {
                !docs && !loading ?
                <Void 
                adjustposition
                title={message} 
                description="We couldn't load your content, please check your network connection" />
                : null
            }
                
        </StyledGallery>
    )
    
}





const mapStateToProps = state =>{
    return {
        state: state.photo
    }
}
const mapDispatchToProps = dispatch =>{
    return{
        dispatch: (action)=>{
            dispatch(action)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Gallery)