export const FETCH_PHOTO_BEGIN = ' FETCH_PHOTO_BEGIN'

export const FETCH_PHOTO_SUCCESS = ' FETCH_PHOTO_SUCCESS'

export const FETCH_PHOTO_FAILURE = 'FETCH_PHOTO_FAILURE'

export const LIKE_SUCCESS = "LIKE_SUCCESS"

export const LIKE_FAILURE = "LIKE_FAILURE"

export const UNLIKE_SUCCESS = "UNLIKE_SUCCESS"

export const UNLIKE_FAILURE = "UNLIKE_FAILURE"