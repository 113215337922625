import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter, NavLink } from "react-router-dom"

import styled from 'styled-components'
import Logo from '../../widget/logo/logo';
import CompactNav from './header-widgets/navLinks';
import SearchForm from '../../widget/search-form/search-form';
import Navigation from '../navigation/navigation';

import { ReactComponent as MenuSvg } from '../../../assets/menu-thin.svg'
import { ReactComponent as MenuOpenSvg } from '../../../assets/menu-fill.svg'
import screenSize from '../../../utilities/checkScreenSize';



const StyledHeader = styled.header`
    width: 100%;
    box-sizing:border-box;
    position: fixed;
    top: 0;
    z-index: 99999;
    background: #fff;
    padding: 10px 15px;
    height: 60px;
    & .nav-wrap{
        display: flex;
        justify-content: center;
        padding-right: 20px !important;
    }
    & .form-fix{
        font-size: 14px;
    }
    & svg{
        margin-top: 0px;
        cursor: pointer
    }
    & .logo,
    .logo-wrapper{
        flex: .5;
        display: flex;
        justify-content: center
    }
    
    // Home header
    &.home-header{
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    
    @media(max-width: 768px){
        &.home-header{
            padding: 15px 20px;
        }
        &.home-header .logo,
        .logo-wrapper{
            display: flex;
            justify-content: flex-start;
        }
        &.home-header .nav-wrap{
            justify-content: flex-end;
        }
        padding: 0px ;
        & .nav-wrap{
            padding-right: 0px !important;
        }
    }
`


const Header = (props)=>{
    const { state, location: { pathname } = {} } = props
    const { user } = state || {}
    const [ currentUser, setCurrentUser ] = useState(null)

    useEffect(()=>{
        const { authenticatedUser } = user || {}
        authenticatedUser && setCurrentUser(authenticatedUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ state ])


    const route = pathname.split('/')[1]
    const isHome = route === ''   ? true : false
    
    const [ toggleNav, setToggleNav ] = useState(false)
    const [ home,setHome ] = useState(false)

    const handleEvent = ()=>{
        setToggleNav(!toggleNav)
    }
    useEffect(()=>{
        if( isHome ){
            setHome(true)
        }else{
            setHome(false)
        }
        setToggleNav(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ route ])    
    
    
    const adjustIcons =  isHome ? {
        fill:  '#fff',
        background: 'rgba(0,0,0,.1)',
        borderRadius: '100%',
        padding: '8px'
    }
    : null
    const { innerWidth } = screenSize() || {}
    const actionLogo = innerWidth > 768 ? true : home



    return(
        <>
            <StyledHeader isHome={true} className={`no-wrap grid align-c centered ${ isHome ? 'home-header' : ''} `}>
                <div className='logo-wrapper'>
                    {
                        <NavLink to='/' 
                        className='logo-link' 
                        onClick={ (e)=>{ !actionLogo && e.preventDefault()}}>
                            <Logo compact goBack={actionLogo} {...props} />
                        </NavLink>
                    }
                </div>
                {
                    !isHome &&
                    <SearchForm 
                        autofocus='false'
                        placeholder='Search for anything...' 
                    />
                }
                <ul className='no-padding no-wrap grid align-c nav-wrap' style={{flex: .5}}>
                    <CompactNav 
                        handleNav={handleEvent} 
                        adjustIcons={adjustIcons}
                        user={currentUser}
                    /> 
                        <NavLink to='#'>
                            {
                                toggleNav ? 
                                <MenuOpenSvg style={adjustIcons} onClick={handleEvent} />
                                : <MenuSvg style={adjustIcons} onClick={handleEvent}/>
                            }
                        </NavLink>
                </ul>   
            </StyledHeader>
            <Navigation 
            closeNav={handleEvent} 
            isOpen={toggleNav}
            state={currentUser}
            />    
        </>
    )
}




const mapStateToProps = state =>{
    return{
        state: {
            login: state.login,
            user: state.user
        }
    }
}
export default withRouter(connect(mapStateToProps, null)(Header) )
