import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components';

import { StyledResponsivePhotoTab } from '../profile/photo-tab';
import TopBar from '../widget/topbar/top-bar';
import getCollectionInfo from '../../utilities/getCollectionInfo';
import Loader from '../widget/loader/loader';
import Photo from '../widget/photo/photo';
import randID from '../../utilities/randID';
import Void from '../widget/void-content/void';
import { _getCollection } from '../../container/redux/services/collection.service';


const StyledCollectionsView = styled.div`
    padding: 0px 0px 30px 0px;
    position: relative;
    & .collection-content {
        margin-top: -200px;
        min-height: 50vh;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 5px 5px 0px 0px;
        background: #fff;
    }
    & .collection-content .void-wrapper{
        z-index: 1;
        width: 100%;
    }
    @media(max-width: 768px){
        & .collection-content{
            padding: 10px;
        }
    }
    
` 
const Slant = styled.div`
        clip-path: polygon(0 100%, 100% 100% , 100% 0vh, 100% -90vh);
        transition: .4s;
        overflow: visible;
        background: white;
        height: 200px;
        top: 150px;
        left: 0px;
        width: 100%;
        position: absolute;

    @media(max-width: 768px){
        clip-path: polygon(0 100%, 100% 100% , 100% 0vh, 0% 20vh);
    }
` 


const CollectionsView = props => {

    const { 
        match,
        state: { 
            collections,
            collectionView,
            error,
            loading
        },
        dispatch
    } = props

    // Check through the albums in state first before making a request
    const { docs } = collections || {}
    const { params: { id:matchID }={}} = match || {}
    const collectionId = match.url.split('/')[2] || "" 
    const collectionTitle = matchID.split('_').join(' ') || ''
    
    const stateCollection = getCollectionInfo( collectionId, docs ) 
    const { 
        id, 
        description, 
        photos, 
        name: collectionName, 
    } = stateCollection || collectionView || {}
   
    const [ searching, setSearching ] = useState(true)
    useEffect(()=>{
        if( stateCollection || collectionView ){
            setSearching(false)
        }
        if(error){
            setSearching(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ collectionId, collectionView, stateCollection, error ])
   
    // Initiate an api request if none in state
    useEffect(()=>{
        if(!stateCollection){
            _getCollection(collectionId, dispatch)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ collectionId ])

    


    const lengthOfCollection = photos ? photos.length : 0
    const title = `${collectionName}`
    const coverImage = lengthOfCollection > 1 ? photos[ lengthOfCollection - 1].url : null 
    // Fix slant shape on smaller screens with more contents
    const responsiveSlant = {
        top: title.length > 50 ? '250px' : '150px' 
    }
    const hasData = stateCollection || collectionView


    

    return(
        <>
            {
                !searching && !loading && hasData &&
                <StyledCollectionsView>
                    <TopBar 
                        title={title} 
                        description={description} 
                        cover={coverImage} 
                        customHeight='120px'
                        extra1={lengthOfCollection.toString()}
                    >Hllo</TopBar>
                    <Slant style={responsiveSlant} className='slant-wrapper'></Slant>
                    <div className='container'>
                        {
                            lengthOfCollection ? 
                            <StyledResponsivePhotoTab 
                                className='collection-content'
                                padding='5px'
                            >
                            {
                                photos.map( () =>{
                                    return <Photo payload={id} key={randID()} />
                                }) 
                            }
                            </StyledResponsivePhotoTab>
                            : 
                            <div className='void-wrapper'>
                                <Void description="You have not added any photos to this collection" />
                            </div>
                        }
                    </div>
                </StyledCollectionsView>
            }
            {
                ( searching || loading )  &&
                searching  && <Loader message="Loading your album, please wait..." />  
            }
            {
                error && 
               <Void 
                    adjustposition
                    title={`No collection found for ' ${collectionTitle}' `} 
                    description="Please check your spelling or URL and try again"
                /> 
            }
        </>
    )
}



const mapStateToProps = (state) => ({
    state: state.collection
})
const mapDispatchToProps = (dispatch)=>{
    return{
        dispatch: action =>{dispatch(action)}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsView)