
// PHOTO UPLOAD
export const UPLOAD_PHOTO_REQUEST = "UPLOAD_PHOTO_REQUEST"

export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_SUCCESS"

export const UPLOAD_PHOTO_FAILURE = "UPLOAD_PHOTO_FAILURE"

export const RESET_UPLOAD_STATE = "RESET_UPLOAD_STATE"


