import * as actions from './auth-action'


export const loginRequest = ()=>{
    return{
        type: actions.LOGIN_REQUEST
    }
}

export const loginSuccess = (payload)=>{
    return{
        type: actions.LOGIN_SUCCESS,
        payload
    }
}


export const loginFailure = (payload)=>{
    return{
        type: actions.LOGIN_FAILURE,
        payload
    }
}

// LOGOUT
export const logoutRequest = ()=>{
    return{
        type: actions.LOGOUT_REQUEST,
    }
}

export const logoutSuccess = (payload)=>{
    return{
        type: actions.LOGOUT_SUCCESS,
        payload
    }
}

export const logoutFailure = (payload)=>{
    return{
        type: actions.LOGOUT_FAILURE,
        payload
    }
}



// REGISTRATION

export const registerRequest = ()=>{
    return{
        type: actions.REGISTER_REQUEST
    }
}

export const registerSuccess = (payload)=>{
    return{
        type: actions.REGISTER_SUCCESS,
        payload
    }
}

export const registerFailure = (payload)=>{
    return{
        type: actions.REGISTER_FAILURE,
        payload
    }
}

// PASSWORDS
export const forgotPasswordRequest = ()=>{
    return{
        type: actions.FORGOT_PASSWORD_REQUEST
    }
}

export const forgotPasswordSuccess = ()=>{
    return{
        type: actions.FORGOT_PASSWORD_SUCCESS
    }
}

export const forgotPasswordFailure = (payload)=>{
    return{
        type: actions.FORGOT_PASSWORD_FAILURE,
        payload
    }
}

// RESET PASSWORD

export const resetPasswordRequest = ()=>{
    return{
        type: actions.RESET_PASSWORD_REQUEST
    }
}

export const resetPasswordSuccess = ()=>{
    return{
        type: actions.RESET_PASSWORD_SUCCESS
    }
}

export const resetPasswordFailure = (payload)=>{
    return{
        type: actions.RESET_PASSWORD_FAILURE,
        payload
    }
}
