/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { withRouter } from "react-router-dom"
import { connect } from 'react-redux'

import styled from 'styled-components';
import { ReactComponent as PaintSvg } from '../../assets/paint.svg'
import Photo from '../widget/photo/photo';
import randID from '../../utilities/randID';
import TabIntro from './tab-intro';
import InfiniteLoader from '../widget/loader/infiniteLoader';
import PrimaryButton from '../widget/buttons/buttons';
import { _fetchUserPhoto } from '../../container/redux/services/photo.service';


export const StyledResponsivePhotoTab = styled.div`
    columns: ${ props => props.related ? 4 : 3};
    margin: auto;
    padding: ${ props => props.padding ? props.padding : ''};


    
    @media screen and (min-width: 768px){
        max-width: 85%;
        column-gap: 30px;
        padding-top: 50px;
    }
    @media(max-width: 1000px){
        columns: ${ props => props.related ? 3 : 2 };
    }
    @media(max-width: 600px){
        columns: ${ props => props.related ? 2 : 1};
        padding: ${ props => props.padding ? props.padding : '0px'}
    }
`


const StyledUploadInit = styled.div`
    margin-top: 20px;
    text-align: center;
    padding: 20px;
    & svg{
        transform: scale(3);
        fill: #aaa
    }
    & p {
        padding-top: 8px;
        font-size: 1.2em;
        color: #555
    }
    & button {
        margin: auto
    }
`



const PhotoTab = (props)=>{
    const { history,  photos, currentUser, isAuth, dispatch } = props
    const { username } = currentUser || {}

    const { userPhotos, currentUser:photoUser, loading } = photos || {}
    const { docs } = userPhotos || {}
    const length = docs ? docs.length : '...'
    
    useEffect(()=>{
        if( !docs || photoUser !== username ){
            _fetchUserPhoto( username, dispatch )
        }
    },[ username ])




    
    return(
        <>
            
           {
                docs && length && !loading ?
                    <> 
                        <TabIntro 
                            title={`Photos ${isAuth ? 'you' : ''} uploaded (${length})`}
                            description='Including public and private photos' />
                        <StyledResponsivePhotoTab>
                            {
                                docs.map( photo =>{
                                    return <Photo payload={photo} key={randID()}  />
                                })
                            }
                        </StyledResponsivePhotoTab>
                    </>
                    : 
                    loading ? <InfiniteLoader />
                    :
                    <StyledUploadInit>
                        <PaintSvg />
                        <p>Let's get your albums interesting!</p>
                        <PrimaryButton 
                            background='var(--darker-blue)'
                            onClick={()=>{history.push('/upload')}} 
                            className='btn'>START UPLOADING
                        </PrimaryButton>
                    </StyledUploadInit>
            }
        </>
    )
}





const mapStateToProps = (state) => ({
    photos: state.photo,
})
const mapDispatchToProps = (dispatch)=>{
    return{
        dispatch: action =>{dispatch(action)}
    }
}


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(PhotoTab))
