
// CHANGE PROFILE IMAGE

export const AVATAR_UPLOAD_REQUEST = 'AVATAR_UPLOAD_REQUEST'

export const AVATAR_UPLOAD_SUCCESS = 'AVATAR_UPLOAD_SUCCESS'

export const AVATAR_UPLOAD_FAILURE = 'AVATAR_UPLOAD_FAILURE'


// ACCOUNT UPDATE

export const UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST'

export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS'

export const UPDATE_ACCOUNT_FAILURE = 'UPDATE_ACCOUNT_FAILURE'


// PASSWORD UPDATE

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'

export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

// ACCOUNT DELETION

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST'

export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS'

export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE'


