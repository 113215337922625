import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components';
import { NavLink } from 'react-router-dom'
import { AuthWrapper, StyledAuthForm } from './auth-style';
import { AuthBackgroundstyles } from './login'
import MessageAlert from '../widget/messages-alert/message';
import { _register } from '../../container/redux/services/user.service';
import sendMessageWithClear from '../../utilities/sendMessageAlert';
import Modal from '../widget/modals/modal'
import randID from '../../utilities/randID';



const RegWrapper = styled(AuthWrapper)``
const RegAuthForm = styled(StyledAuthForm)`
    padding-top: 10px;
    & .form-head,
    & .form-foot{
        padding-top: 5px;
    }
`

const RegistrationForm = ({eventHandler, state, extra })=>{
    const { 
        name, 
        username,
        email, 
        password, 
        passwordCheck, 
        isError, 
        errorMessage 
    } = state || {}
    
    // Reducer state
    const {
        loading,
        error,
    } = extra || {}





    return(
        <RegAuthForm className='register'>
            <form onSubmit={eventHandler}>
                <div className='centered-text form-head'>
                    <h2 className='auth-title'>Join Pixare</h2>
                </div>
                <div className='no-padding divider'></div>
               <div className='body'>
                   {    error &&
                        error.map( e =>{
                            const { msg } = e
                            return (
                                <MessageAlert 
                                    dismissable={true}
                                    icon='info' 
                                    type='error' 
                                    message={msg} 
                                    key={randID()}
                                />
                            )
                        })
                   }
                    <div className='field'>
                        <input 
                            onChange={ eventHandler } 
                            value={ name }
                            type='text' 
                            name='name' 
                            placeholder='Name' 
                            className='form-fix' 
                            required
                        />
                    </div>
                    <div className='field'>
                        <input 
                            onChange={ eventHandler } 
                            value={ username } 
                            type='text' 
                            name='username' 
                            placeholder=' Username  ( for personalisation )' 
                            className='form-fix' 
                            required
                        />
                    </div>
                    <div className='field'>
                        <input 
                            onChange={ eventHandler } 
                            value={ email }
                            type='email' 
                            name='email' 
                            placeholder='Email address' 
                            className='form-fix' 
                            required
                        />
                    </div>
                    <div className='field'>
                        <input 
                            onChange={ eventHandler } 
                            value={ password }
                            autoComplete='off' 
                            type='password' 
                            name='password' 
                            placeholder='Password' 
                            className='form-fix' 
                            required
                        />
                    </div>
                    <div className='field'>
                        <input 
                            onChange={ eventHandler } 
                            value={ passwordCheck }
                            autoComplete='off' 
                            type='password' 
                            name='passwordCheck' 
                            placeholder='Re-enter Password' 
                            className='form-fix' 
                            required
                        />
                       {
                           isError &&
                            <small className='red-t error-check'>
                                {errorMessage}
                            </small>
                       }
                    </div>
                    <button 
                        disabled={ loading ? 'disabled' : null}
                        type="submit" 
                        className='blue auth-btn full-width'>Create Account</button>
               </div>
                <div className='no-padding divider'></div>
                <div className='centered-text form-foot'>
                    <p>Already a member?</p>
                    <NavLink className='blue-t' to='/login'>Login</NavLink>
                </div>
            </form>
        </RegAuthForm>
    )
}

const Register = (props)=>{
    const { state: {  loading, user, error, message }, dispatch, history } = props

    const [ registerDetail, setRegisterDetail ] = useState({
        name: '',
        username: '',
        email: '',
        password: '',
        passwordCheck: '',
        isError: false,
        errorMessage: ''
    })

    console.log(user)

    // REDIRECT TO LOGIN AFTER SUCCESSFUL REGISTRATION
    useEffect(()=>{
        if( user ){
            sendMessageWithClear(dispatch, message)
            setTimeout(()=>{
                history.push('/login')
            }, 1000)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ user, dispatch ])

    const eventHandler = (e)=>{
        e.preventDefault()
        const { value, name } = e.target
        setRegisterDetail({
            ...registerDetail,
            [name] : value
        })
        if(e.type === 'submit'){
            // MAKE SURE PASSWORDS MATCH
            if( registerDetail.passwordCheck !== registerDetail.password ){
                setRegisterDetail({
                    ...registerDetail,
                    isError: true,
                    errorMessage: 'Passwords do not match!'
                })
            }else{
                setRegisterDetail({
                    ...registerDetail,
                    isError: false,
                    errorMessage: ''
                })
                // Dispatch form
                const { name, email, password, username } = registerDetail
                dispatch(_register({
                    name,
                    username,
                    email,
                    password 
                }))
            }
        }
    }


    return(
        <>
            <RegWrapper style={AuthBackgroundstyles} className="join-wrapper">
                <div className="grid align-c padded-20 demo">
                    <h1>Best place to find awesome collections of stock photos from around Africa</h1>
                    <p>Join over <b>20,000</b> creative African photographer and share memories! </p>
                </div>
                <RegistrationForm  
                    eventHandler={eventHandler} 
                    state={registerDetail} 
                    extra={{loading, error,}}
                />
            </RegWrapper>
            {
                loading &&
                <Modal title='Creating account'>
                    <p className='centered-text'>We are setting up your account, please wait...</p>
                </Modal>
            }
        </>
    )
}




const mapStateToProps = state =>{
    return{
        state: state.register
    }
}

const mapDispatchToProps = dispatch =>{
    return{
        dispatch: (action)=>{
            dispatch(action)
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Register)