import * as actions  from "../actions/upload-action/upload-actions";


const INITIAL_STATE = {
    currentUpload: [],
    uploading: false,
    uploadComplete: false,
    uploadProgressReport: [
        {progress: 50, error: true, message: 'Unknown error'},
        {progress: 90, error: false},
        {progress: 90, error: false, message: 'Network issues'},
    ]
}

const UploadReducer = ( state = INITIAL_STATE, action)=>{
    const { type } = action

    switch(type){
        case actions.UPLOAD_PHOTO_REQUEST:
            return{
                ...state,
                uploading: true,
            }
        case actions.UPLOAD_PHOTO_SUCCESS:
            return{
                ...state,
                uploading: false,
                uploadComplete: true
            }
        case actions.RESET_UPLOAD_STATE:
            return{
                ...state,
                uploadComplete: false
            }
        case actions.UPLOAD_PHOTO_FAILURE:
            return{
                ...state,
                uploading: false,
            }
        default: 
            return state
    }
}

export default UploadReducer