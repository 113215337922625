import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components';
import RouterMain from '../../container/router/router';
import sendMessageWithClear from '../../utilities/sendMessageAlert';
import { _getUser } from '../../container/redux/services/user.service';
import authHeader from '../../utilities/authHeader';
import Header from '../Common/header/header'
import Void from '../widget/void-content/void';
import TagNav from '../Common/tag-nav/tagNav';
import { _fetchLikedPhotos } from '../../container/redux/services/photo.service';


const StyledAppWrapper = styled.section`
    @media(max-width: 768px){
        padding-top: 0px !important;
    }
`

const AppWrapper = (props)=>{
    const { location: { pathname } = {}, state } = props

    const route = pathname.split('/')[1]
    const isHome = route === ''   ? true : false
    const adjustContent = {
        marginTop: isHome ? 0 : '60px',
        paddingTop: isHome ? 0 : '10px'
    }



    // MANAGE LOG OUT SESSIONS
    const { 
        login: {
            logOut: { 
                loading, 
                error, 
                message 
            } = {} 
        } = {},
        user: {
            isLoggedin,
            authenticatedUser
        }
    } = state
    const { username } = authenticatedUser || {}


    const dispatch = useDispatch()
    useEffect(()=>{
        loading && sendMessageWithClear( dispatch, 'Logging out...')                
    }, [ loading, dispatch ])

    useEffect(()=>{
       message && window.location.reload(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ message, dispatch])

    useEffect(()=>{
        error &&  sendMessageWithClear( dispatch, error )
    }, [ error , dispatch ])

    useEffect( ()=>{
        if( authHeader() && !isLoggedin ){
            dispatch(_getUser())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isLoggedin ])

    // Fetch likedPhoto at init
    useEffect(()=>{
        if( isLoggedin ){
            _fetchLikedPhotos( dispatch, username )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ isLoggedin ])

    const online = window.navigator.onLine

   





    return(
        <>
            <Header />
            {
                online ?
                <>
                    <StyledAppWrapper style={adjustContent}>
                        {/* {
                            <TagNav route={route}/>
                        } */}
                        <RouterMain />
                    </StyledAppWrapper>
                </>
                : 
                <Void
                title='Network Error'
                adjustposition
                description='Please check your network connection!' />
            }
        </>
    )
}


const mapStateToProps = state =>{
    return{
        state: {
            login: state.login,
            user: state.user
        }
    }
}
export default connect(mapStateToProps, null)(withRouter(AppWrapper))
