import * as actions from '../actions/photo-view-action/photo-view-action'

const INITIAL_STATE = {
    match: null,
    message: '',
    loading: false,
}

const ViewReducer = ( state = INITIAL_STATE, action )=>{
    const { type, payload } = action

    switch(type){
        case actions.FIND_MATCH_BEGIN:
                return{
                    ...state,
                    loading: true,
                    message: '',
                    match: state.match
                }
        case actions.FIND_MATCH_SUCCESS:
            
            return{
                ...state,
                loading: false,
                match: { ...payload.data }
            }
        case actions.FIND_MATCH_FAILURE:
            return{
                ...state,
                loading: false,
                message: payload.msg,
                match: null
            }

        default:
            return state
    
    }
}

export default ViewReducer