import styled from 'styled-components';

export const AuthWrapper = styled.div`
    min-height: 93vh;
    display: flex;
    flex-wrap: nowrap;
    & > *{
        flex: 1
    }
    & .demo{
        background: #fff;
        color: var(--secondary-color)
    }
    & .form-foot {
        letter-spacing: 1.2px;
        font-size: 16px
    }
    & .form-foot a{
        margin-top: 10px;
    }
        
    @media(max-width: 768px){
        & .demo h1{
            font-size: 1.5em
        }
        & .login form h2{
            font-size: 1.2em
        }
        & .login {
            flex: 1.5;
        }
        & .demo {
            min-height: 30vh
        }
        
    }

        
    @media(max-width: 600px){
        flex-direction: column;      
        & .login{
            padding-top: 0px;
        }
        & .demo {
            display: none;
            background: transparent;
            color: #fff;
            max-height: fit-content;
            min-height: 0px;
            padding: 10px 10px 15px 10px !important
        }
        & .demo p{
            font-size: 14px;
            margin: 0px;
            text-shadow: var( --soft-shadow);
        }

        & .demo h1{
            font-size: 1.2em;
            text-shadow: var( --soft-shadow)
        }
        
    }
`
export const StyledAuthForm = styled.div`
    min-width: 30%;
    max-width: 450px;
    background: #fff;
    padding: 20px 0px;

    & form > div{
        padding: 20px;
    }
    & form .divider.mt-30{
        margin-top: 60px;
    }
    & form .auth-btn{
        background: var(--primary-color);
        font-size: 18px;
        font-weight: 600;
        padding: 20px;
        margin-top: 20px;
        box-shadow:  0px 3px 5px rgba(0,0,0,.08) ;
    }
    & form .auth-btn:disabled{
        background: #eee;
        cursor: not-allowed
    }
    @media(max-width: 600px){
        max-width: 100%
    }

` 
