import * as authActions from '../actions/auth-actions/auth-action-creator'
import * as userActions from '../actions/user-action/user-action-creator'
import * as accountActions from '../actions/acount-actions/acount-action-creator'

import axios from 'axios'
import authHeader from '../../../utilities/authHeader';
import AxiosCall from '.';


const backUpErr = (payload)=>{
    return [{ msg: payload.message }]
}

// LOGIN / LOGOUT

export const _login = (payload)=>{
    return (dispatch)=>{
        dispatch(authActions.loginRequest())
        const API_ENDPOINT = AxiosCall(`auth/login`)

        const MY_HEADERS = new Headers()
        MY_HEADERS.append("Content-Type", "application/json")

        const REQUEST_OPTIONS = {
            method: 'POST',
            headers: MY_HEADERS,
            data: payload,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data } = response
            dispatch(authActions.loginSuccess(data))
        })
        .catch( error => {
            const { response: { data: { errors } = {} } = {} } = error 
            dispatch(authActions.loginFailure(errors || backUpErr(error) ))
        })

    }
}

// LOGOUT
export const _logOut = ()=>{
    return (dispatch)=>{
        dispatch(authActions.logoutRequest())
        const API_ENDPOINT = AxiosCall(`auth/logout`)

        const MY_HEADERS = {
            Authorization: `Bearer ${authHeader()}`
        }
        

        const REQUEST_OPTIONS = {
            method: 'POST',
            headers: MY_HEADERS,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data } = response
            dispatch(authActions.logoutSuccess(data))
        })
        .catch( error => {
            const { response: { data: { errors } = {} } = {} } = error 
            dispatch(authActions.logoutFailure(errors || backUpErr(error)))
        })
    }
}

// REGISTRATION  

export const _register = (payload)=>{
    
    return (dispatch)=>{
        dispatch(authActions.registerRequest())
        const API_ENDPOINT = AxiosCall(`auth/register`)
        const MY_HEADERS = new Headers()
        MY_HEADERS.append("Content-Type", "application/json")

        const REQUEST_OPTIONS = {
            method: 'POST',
            headers: MY_HEADERS,
            data: payload,
            redirect: 'follow'
        }

        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data } = response
            dispatch(authActions.registerSuccess(data))
        })
        .catch( error => {
            const { response: { data: { errors }= {} } = {} } = error 
            dispatch(authActions.registerFailure(errors || backUpErr(error)))
        })
    }

}


// FORGOT PASSWORD
export const _forgotPassword = (payload)=>{
    return (dispatch)=>{
        dispatch(authActions.forgotPasswordRequest())
        const API_ENDPOINT = AxiosCall(`auth/forgot-password`)

        const MY_HEADERS = new Headers()
        MY_HEADERS.append("Content-Type", "application/json");

        const REQUEST_OPTIONS = {
            method: 'POST',
            headers: MY_HEADERS,
            data: payload,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data } = response
            dispatch(authActions.forgotPasswordSuccess(data))
        })
        .catch( error => {
            const { response: { data: { error: err } = {} } = {} } = error 
            dispatch(authActions.forgotPasswordFailure(err || backUpErr(error)))
        })

    }
}

// RESET PASSWORD
export const _resetPassword = ({oldPassword, newpassword})=>{
    return (dispatch)=>{
        dispatch(authActions.resetPasswordRequest())
        const API_ENDPOINT = AxiosCall(`auth/forgot-password`)
        var DATA = {"oldPassword": oldPassword, 'newPassword': newpassword }

        const MY_HEADERS = new Headers()
        MY_HEADERS.append("Content-Type", "application/json");

        const REQUEST_OPTIONS = {
            method: 'GET',
            headers: MY_HEADERS,
            data: DATA,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { response: { data } = {} } = response
            dispatch(authActions.resetPasswordSuccess(data))
        })
        .catch( error => {
            const { response: { data: { errors: { 0: { msg } = {} } = {} } = {} } = {} }  = error || {}
            dispatch(authActions.resetPasswordFailure({msg}))
        })

    }
}



//ACCOUNT RELATED SERVICES

// ACCOUNT UPDATE
export const _updateAccount = (payload)=>{
    

    return (dispatch)=>{
        dispatch(accountActions.updateAccountRequest())
        const API_ENDPOINT = AxiosCall(`profile`)
        const MY_HEADERS = {
            Authorization: `Bearer ${authHeader()}`
        }
        const REQUEST_OPTIONS = {
            method: 'PATCH',
            data: payload,
            headers: MY_HEADERS,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data } = {} } = response
            dispatch(accountActions.updateAccountSuccess(data))
        })
        .catch( error => {
            const { response: { data: { errors } = {} } = {} }  = error || {}
            dispatch(accountActions.updateAccountFailure(errors))
        })

    }
}


// CHANGE PASSWORD
export const _changePassword = (payload)=>{
    
    return (dispatch)=>{
        dispatch(accountActions.changePasswordRequest())

        const API_ENDPOINT = AxiosCall(`profile/change-password`)
        const MY_HEADERS = {
            Authorization: `Bearer ${authHeader()}`
        }
        const data = { 
            password: {
            old: payload.oldPassword,
            new: payload.newPassword
        } }

        const REQUEST_OPTIONS = {
            method: 'PATCH',
            data,
            headers: MY_HEADERS,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data } = {} } = response
            dispatch(accountActions.changePasswordSuccess(data))
        })
        .catch( error => {
            const { response: { data: { errors } = {} } = {} }  = error || {}
            dispatch(accountActions.changePasswordFailure(errors))
        })

    }
}


// CHANGE PROFILE IMAGE 
export const _changeAvatar = (payload)=>{
    return (dispatch)=>{
        dispatch(accountActions.avatarChangeRequest())
        const API_ENDPOINT = AxiosCall(`profile/change-avatar`)
        const MY_HEADERS = {
            'Authorization': `Bearer ${authHeader()}`,
            'Content-Type': 'multipart/form-data'
        }
        const FORM_DATA = new FormData();
        FORM_DATA.append("avatar", payload, "30401043757_47c0f7f02f_k.jpg");

    
        const REQUEST_OPTIONS = {
            headers: MY_HEADERS,
            data: FORM_DATA,
            method: 'PATCH',
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data: { msg } } = {} } = response
            dispatch(accountActions.avatarChangeSuccess({msg}))
        })
        .catch( error => {
            const { response: { data: { errors } = {} } = {} }  = error || {}
            dispatch(accountActions.avatarChangeFailure(errors))
        })

    }
}

// ACCOUNT DELETION
export const _deleteAccount = ()=>{
    return (dispatch)=>{
        dispatch(accountActions.deleteAccountRequest())
        const API_ENDPOINT =  AxiosCall(`profile/delete-account`)
        const MY_HEADERS = {
            'Authorization': `Bearer ${authHeader()}`,
        }

        const REQUEST_OPTIONS = {
            method: 'DELETE',
            headers: MY_HEADERS,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data: { msg } } = {} } = response
            dispatch(accountActions.deleteAccountSuccess(msg))
        })
        .catch( error => {
            const { response: { data: { msg } = {} } = {} }  = error || {}
            dispatch(accountActions.deleteAccountFailure({msg}))
        })
    }
}





//USER RELATED SERVICES

// GET USER
export const _getUser = ()=>{
    return (dispatch)=>{
        dispatch(userActions.getUserRequest())
        const API_ENDPOINT = AxiosCall(`auth/user`)
        const MY_HEADERS = {
            Authorization: `Bearer ${authHeader()}`
        }

        const REQUEST_OPTIONS = {
            method: 'GET',
            headers: MY_HEADERS,
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data: { user } } = {} } = response
            dispatch(userActions.getUserSuccess(user))
        })
        .catch( error => {
            dispatch(userActions.getUserFailure(error.message))
        })

    }
}


// GET USER BY USERNAME
export const _getUserByUsername = (payload)=>{
    return (dispatch)=>{
        dispatch(userActions.userProfileRequest())
        const API_ENDPOINT = AxiosCall(`user/profile?username=${payload}`)
    

        const REQUEST_OPTIONS = {
            method: 'GET',
            redirect: 'follow'
        }
        axios( API_ENDPOINT , REQUEST_OPTIONS)
        .then( response => {
            const { data: { data: { user } } = {} } = response
            dispatch(userActions.userProfileSuccess(user))
        })
        .catch( error => {
            const { response: { data: { errors } = {} } = {} }  = error || {}
            dispatch(userActions.userProfileFailure(errors))
        })

    }
}




