/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import PrimaryButton from '../buttons/buttons';
import randID from '../../../utilities/randID';
import InfiniteLoader from '../loader/infiniteLoader';

const StyledForm = styled.form`
    & .form-switch button {
        margin: 5px;
    }
    & .form-fix{
        padding: 12px 10px
    }
    & > div {
        background: var(--backdrop);
        border-radius: 3px;
        border: 1px solid #ddd;
        padding: 10px;
    }

    & small.passError{
        margin: 0px;
    }

    @media(max-width: 600px){
        & small.passError{
            font-size: 12px;
        }
    }
`


const UpdateForm = (props)=>{
    const { 
        data: {
            name, 
            email,
            username,
            location, 
            interests, 
            instagram, 
            twitter,  
            bio,
        } = {},
        handleEvent,
        waiting,
        state,
    } = props
    let { notice, error } = state 

    const [ userDetail, setUserDetail ] = useState({
        name, 
        email, 
        username,
        location, 
        instagram, 
        twitter,  
        bio,
        interests, 
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: ''
    })

    const [ passError, setError ] = useState(false)
    const [ form, setForm ] = useState(1)
    



    
    // Export form
    const exportFrom = (e)=>{
        e.preventDefault()
        const { name, value } = e.target
        if(e.type === 'submit'){
            if( userDetail.newPasswordConfirm !== userDetail.newPassword ){
                setError(true)
            }else{
                setError(false)
                if( form ){
                    handleEvent(userDetail, 1)
                }else{
                    handleEvent(userDetail, 0)
                }
            }
        }else{
            setUserDetail({
                ...userDetail,
                [name]: value
            })
        }
    }



   
    
    return(
        <>
            <div className=' centered-text form-switch'>
                <p> What do you want to do? </p>
                <ul className='grid equal no-padding'>
                    {
                        form ? 
                        <PrimaryButton 
                            onClick={()=>{setForm(0)}}
                            background='#555'
                            disabled={ waiting ? 'disabled' : null}
                        >
                            Change password
                        </PrimaryButton>
                        : null
                    }
                    {
                        !form ?  
                        <PrimaryButton 
                            onClick={()=>{setForm(1)}}
                            background='#555'
                            disabled={ waiting ? 'disabled' : null}
                        >
                            Edit account information
                        </PrimaryButton>
                        : null
                    }
                </ul>
            </div>
            <StyledForm onSubmit={exportFrom}>
                {   
                    form ?
                    <div className='basic-detail'>    
                        <label className='bold-text-1'>Personal</label>
                        <div className='equal grid fields'>
                            <div className='field'>
                                <input 
                                    onChange={exportFrom}
                                    type='text'
                                    name='name'
                                    value={ userDetail.name }
                                    placeholder='Name'
                                    required
                                    className='form-fix'
                                /> 
                            </div>
                            <div className='field'>
                                <input 
                                    onChange={exportFrom}
                                    type='text'
                                    name='email'
                                    value={ userDetail.email }
                                    placeholder='Email address'
                                    required
                                    className='form-fix'
                                /> 
                            </div>
                        </div>
                        <div className='equal grid fields'>
                            <div className='field'>
                                <input 
                                    onChange={exportFrom}
                                    type='text'
                                    name='location'
                                    value={ userDetail.location }
                                    placeholder='Location'
                                    className='form-fix'
                                /> 
                            </div>
                            <div className='field'>
                                <input 
                                    onChange={exportFrom}
                                    type='text'
                                    name='interests'
                                    value={ userDetail.interests }
                                    placeholder='Interests'
                                    className='form-fix'
                                /> 
                            </div>
                        </div>
                        <label className='bold-text-1'>Socials</label>
                        <div className='equal grid fields'>
                            <div className='field'>
                                <input 
                                    onChange={exportFrom}
                                    type='text'
                                    name='instagram'
                                    value={ userDetail.instagram }
                                    placeholder='Instagram'
                                    className='form-fix'
                                /> 
                            </div>
                            <div className='field'>
                                <input 
                                    onChange={exportFrom}
                                    type='text'
                                    name='twitter'
                                    value={ userDetail.twitter }
                                    placeholder='Twitter'
                                    className='form-fix'
                                /> 
                            </div>
                        </div>
                        <div className='field'>
                            <textarea 
                                onChange={exportFrom}
                                name='bio'
                                value={ userDetail.bio }
                                placeholder='Bio'
                                className='form-fix'
                            /> 
                        </div>
                        <PrimaryButton 
                            disabled={ waiting ? 'disabled' : null}
                            type='submit' 
                            background='var(--red)'
                            className='full-width'
                            > { waiting ? 'Saving...' : 'Save changes'}
                        </PrimaryButton>
                        {
                            notice &&
                            <p className='centered-text green-t'>{notice}</p>
                        }
                        {
                            error &&
                            <p className='centered-text red-t'>{error}</p>
                        }
                    </div>
                    :null
                }

                {/* PASSWORD UPDATE FORM */}
                {
                    !form ?
                    <div className='password-change'>
                        <label className='bold-text-1'>Passwords</label>
                        <div className='field'>
                            <input 
                                onChange={exportFrom}
                                type='password'
                                name='oldPassword'
                                value={ userDetail.oldPassword }
                                placeholder='Old password'
                                className='form-fix'
                            /> 
                        </div>
                        <div className='equal grid fields'>
                            <div className='field'>
                                <input 
                                    onChange={exportFrom}
                                    type='password'
                                    name='newPassword'
                                    value={ userDetail.newPassword }
                                    placeholder='New password'
                                    className='form-fix'
                                /> 
                            </div>
                            <div className='field'>
                                <input 
                                    onChange={exportFrom}
                                    type='password'
                                    name='newPasswordConfirm'
                                    value={ userDetail.newPasswordConfirm }
                                    placeholder='Confirm new password'
                                    className='form-fix'
                                /> 
                                {
                                    passError && <small className='passError red-t'> Password do not match</small>
                                }
                            </div>
                        </div>
                        <PrimaryButton 
                            disabled={ waiting ? 'disabled' : null}
                            type='submit' 
                            background='var(--red)'
                            className='full-width'
                            > { waiting ? 'Saving...' : 'Change password'}
                        </PrimaryButton>
                        {
                            waiting && <InfiniteLoader />
                        }
                        {
                            notice &&
                            <p className='centered-text green-t'>{notice}</p>
                        }
                        {
                            error &&
                            <p className='centered-text red-t'>{error}</p>
                        }
                    </div>
                    :null
                }
            </StyledForm>
        </>
    )
}

export default UpdateForm