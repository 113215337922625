
// FOLLOWS
export const FOLLOW_USER_REQUEST = 'FOLLOW_USER_REQUEST'

export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS'

export const FOLLOW_USER_FAILURE = 'FOLLOW_USER_FAILURE'


// UNFOLLOW
export const UNFOLLOW_USER_REQUEST = 'UNFOLLOW_USER_REQUEST'

export const UNFOLLOW_USER_SUCCESS = 'UNFOLLOW_USER_SUCCESS'

export const UNFOLLOW_USER_FAILURE = 'UNFOLLOW_USER_FAILURE'




