import * as actions from './userEngagement'

export const followUserRequest = ()=>{
    return{
        type: actions.FOLLOW_USER_REQUEST
    }
}

export const followUserSuccess = (payload)=>{
    return{
        type: actions.FOLLOW_USER_SUCCESS,
        payload
    }
}

export const followUserFailure = (payload)=>{
    return{
        type: actions.FOLLOW_USER_FAILURE,
        payload
    }
}