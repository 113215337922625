import * as actions from '../actions/alert-actions/alert-actions'
const INITIAL_STATE = {
    messages: []
}

const AlertReducer = (state = INITIAL_STATE, action)=>{
    const { type, payload } = action
    switch(type){
        case actions.LOG_MESSAGE:
            return{
                ...state,
                messages: [
                    ...state.messages,
                    payload,
                ]
            }
        case actions.CLEAR_MESSAGE:
            return {
                ...state,
                messages: payload === "all" ? [] : 
                state.messages.filter( m => m.id !== payload )                
            }
        default: 
                return state
    }

}

export default AlertReducer