import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components';


const Button = styled.button`
    display: block;
    padding: ${ props => props.padding ? props.padding : '8px 20px' };
    color: ${ props => props.color ? props.color : '#fff' };
    font-weight: ${ props => props.bold };
    background: ${ props => props.background ? props.background : 'var(--light-green)'};
    margin: ${props => props.margin};
    border-radius: ${props => props.borderRadius};
    box-shadow:  ${ props => props.shadow || '0px 5px 5px rgba(0,0,0,.08), inset 0px -5px 10px rgba(0,0,0,.1)'}
     ;
    &:hover{
        opacity: .9;
        transition: .3s 
    }
    &:active{
        outline: none;
        transform: scale(.8);
        background: none;
        transition: .1s
    }
    @media(max-width: 500px){
        padding: 10px 30px;
    }
`

const PrimaryButton = (props)=>{
    const { asLink, asLinkExt } = props

    return(
        <Button {...props}>
            {
                asLink ?
                <NavLink to={asLink}>
                    {props.children}
                </NavLink>
                :
                asLinkExt ? 
                    <a {...props} href={asLinkExt}>
                        {props.children}
                    </a>
                :
                <>
                    {props.children}
                </>
            }
        </Button>
    )
}

export default PrimaryButton