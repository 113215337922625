import React from 'react'
import styled from 'styled-components';

const StyledProgressBar = styled.div`
    height: 4px;
    width: 100%;
    box-sizing: border-box;
    background: rgba(0, 0, 200, .3);
    & .loader{
        height: 4px;
        background: linear-gradient(to  right, rgba(0, 0, 200, .3), rgba(0, 0, 2000, .8));
		box-sizing: border-box;
		transition: width .5s ease-in;
        animation: purge .3s infinite;
        display: flex;
        justify-content: center;
        position: relative
    }
    & .loader:before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: linear-gradient(to  right, rgba(0, 0, 200, .8), rgba(0, 0, 200, .3));
		animation: purge 1s infinite ;
    }
    
    @keyframes purge{
		from{
            background: linear-gradient(to  right, rgba(0, 0, 200, .3), rgba(0, 0, 2000, .8));
            width: 0px;
        }
        to{
            linear-gradient(to  right, rgba(0, 0, 200, .8), rgba(0, 0, 2000, .3));
            width: 100%
        }
	}
`


export const InfiniteLoader = ()=>{



    return(
        <StyledProgressBar>
            <div className="loader"></div>
        </StyledProgressBar>
    )
}


export default InfiniteLoader