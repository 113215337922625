import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom'
import screenSize from '../../../utilities/checkScreenSize';
import { ReactComponent as ErrorSvg } from '../../../assets/error.svg'
import PrimaryButton from '../buttons/buttons';



const StyledVoid = styled.div`
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    color: #aaa;
    border: none !important;
    & .title{
        max-width: 1000px;
        width: 90%;
        margin: 3px auto;
        color: #777;
    }
    & .description{
        max-width: 1000px;
        margin: 5px auto;
        font-size: 16px;
    }
    & svg.svg{
        transform: scale(2.5);
        top: -40px;
        position: relative;
        fill: red;
        margin: 0px auto !IMPORTANT;
    }
    & a{
        font-weight: 600;
        margin-top: 12px;
    }

    @media(max-width: 500px){
        & .title {
            font-size: 1em;
        }
        & .description{
            max-width: 95%;
        }

    }
` 


export const Void = ({ title, description, link, adjustposition })=>{
    const { innerHeight } = screenSize()
    const { text, route } = link || {}

    const style = adjustposition ? 
            {
                top: ( innerHeight / 2 - 100 ) + 'px'
            } 
        : 
            {
                paddingTop: '100px'
            }
    const svgStyle = adjustposition ? 
            {
                margin: '0px auto !important'
            }
        : null

    return(
        <StyledVoid style={style} className="grid col grey-t centered-text void">
            <ErrorSvg style={svgStyle}/>
            {   title &&
                <h2 className='title'>{title}</h2>
            }
            {
                description &&
                <p className='description'>{description}</p>
            }
            {
                link && 
                <NavLink className='blue-t' to={`${route}`}>
                    <PrimaryButton margin='0px auto'>
                        {text}
                    </PrimaryButton>
                </NavLink>
            }
        </StyledVoid>
    )
}


export default Void