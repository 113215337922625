import * as actions from '../actions/auth-actions/auth-action'

// SAVE USER IN LOCALSTORAGE 
const saveUser = (user)=>{
    localStorage.setItem('regUser', JSON.stringify(user))
}

const INITIAL_STATE = {
    
}
const RegisterReducer = ( state = INITIAL_STATE, action )=>{
    const { type, payload } = action
    switch( type ){
        case actions.REGISTER_REQUEST:
            return{
                loading: true
            }
        case actions.REGISTER_SUCCESS:
            const { data: { msg, user } = {}} = payload
            saveUser(user.email)
            return{
                message: msg,
                user
            }
        case actions.REGISTER_FAILURE:
            return{
                error: payload
            }

        default: 
            return state
    }
}

export default RegisterReducer